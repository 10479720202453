import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3, H4 } from "components/Typography/text.styled";
import React, { useEffect, useState } from "react";
import { useAuthStore } from "stores/authStore/authStore";
import { GigDto } from "model/Gigs";
import { CircularProgress } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Api } from "services/api/api.service";
import { FontFamily } from "config/font";
import Dialog from "@mui/material/Dialog";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Color } from "config/colors";
export const GigsPage: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [authState] = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [gigs, setgigs] = useState<GigDto[]>([]);
  const [modalData, setModalData] = useState<GigDto>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [gigsPageNumber, setGigsPageNumber] = useState(0);
  useEffect(() => {
    if (authState.signedIn) {
      getInitialData();
    }
  }, [authState]);

  const getInitialData = () => {
    if (authState.signedIn) {
      setIsLoading(true);
      Api()
        .admin.getGigs(gigsPageNumber)
        .then((res) => {
          setgigs(res);
          setIsLoading(false);
        });
    }
  };
  const loadMoreGigs = () => {
    setGigsPageNumber(gigsPageNumber + 1);
    Api()
      .admin.getGigs(gigsPageNumber + 1)
      .then((res) => {
        setgigs([...gigs, ...res]);
      });
  };
  const showGigData = (gig: GigDto) => {
    setModalData(gig);
    setModalOpen(true);
  };

  const renderTable = (rows: GigDto[]) => {
    return (
      <>
        {isLoading && <CircularProgress size={18} color="inherit" />}
        {!isLoading && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Id</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Company</TableCell>
                    <TableCell align="center">Hourly rate</TableCell>
                    <TableCell align="center">Resolution</TableCell>
                    <TableCell align="center">Type of Gig</TableCell>
                    <TableCell align="center">Gigapay enabled</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row: GigDto) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { opacity: 0.4 },
                      }}
                      onClick={() => showGigData(row)}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.role}</TableCell>
                      <TableCell align="center">
                        {row.company?.companyName}
                      </TableCell>
                      <TableCell align="center">
                        {row.minRate} - {row.maxRate}
                      </TableCell>
                      <TableCell align="center">{row.resolution}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">
                        {row.gigapayEnabled ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="right">{row.status}</TableCell>

                      {/* <TableCell align="right">
                        <span
                          onClick={() => showGigData(row)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Show
                        </span>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="center">
              <Box mt={2}>
                <Button variant="contained" onClick={() => loadMoreGigs()}>
                  Show more
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </>
    );
  };
  const renderModalData = () => {
    return (
      <>
        <div>
          <Grid
            style={{ padding: "2%" }}
            container
            rowSpacing={0}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} xs={12}>
              <H4>Gig</H4>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Role</H4>
              <p>{modalData?.role || ""}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Hourly rate</H4>
              <p>
                {modalData.minRate} - {modalData.maxRate}
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Resolution</H4>
              <p>{modalData?.resolution || ""}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Type of Gig</H4>
              <p>{modalData?.type || ""}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Gigapay enabled</H4>
              <p>{modalData?.gigapayEnabled ? "Yes" : "No"}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Status</H4>
              <p>{modalData?.status || ""}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Shifts</H4>
              <p>{modalData?.shifts || "No shifts found"}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Skills</H4>
              {modalData?.skills?.length === 0 && <p>No skills found</p>}
              <p>
                {modalData?.skills &&
                  modalData?.skills?.map((s) => <span>{s.skill}, </span>)}
              </p>
            </Grid>
            <Grid item xs={12}>
              <H4>Description</H4>
              <p>{modalData?.description || ""}</p>
            </Grid>
            <Grid xs={12} ml={3}>
              <H4>Company</H4>
            </Grid>
            <Grid item md={4} xs={12} mt={0}>
              <H4>Name</H4>
              <p>{modalData?.company?.companyName || ""}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <H4>Email</H4>
              <p>{modalData?.company?.email || ""}</p>
            </Grid>
            <Grid item md={4} xs={12} m={0}>
              <H4>Phone</H4>
              <Box gridColumn="span 4">
                {modalData?.company?.phoneNumber || ""}
              </Box>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  return (
    <LoggedInPageContainer>
      <H3>Gigs</H3>
      {renderTable(gigs)}
      <Dialog
        maxWidth="xl"
        style={{ marginLeft: "auto" }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        {modalOpen && renderModalData()}
      </Dialog>
    </LoggedInPageContainer>
  );
};

const style = {
  width: "80vw",
  backgroundColor: Color.White,
  borderRadius: "10px",
  padding: "20px",
  fontFamily: FontFamily.MontserratRegular,
};
