import { CircularProgress, Grid } from "@material-ui/core";
import { BoxStyled } from "components/box/box.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3, H4, Text } from "components/Typography/text.styled";
import moment from "moment";
import Box from "@mui/system/Box";
import React, { useState } from "react";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { Api } from "services/api/api.service";
import { saveAs } from "file-saver";

export const StartPage: React.FC = () => {
  const [paxmlMonth, setPaxmlMonth] = useState(moment().format("YYYY-MM"));
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const getPaxmlTimesForMonth = () => {
    const d = moment(paxmlMonth);
    const firstDateOfMonth = moment(d);
    const lastDateOfMonth = moment(d).set("date", d.daysInMonth());
    return {
      fromDate: firstDateOfMonth.format("YYYYMMDD"),
      toDate: lastDateOfMonth.format("YYYYMMDD"),
    };
  };
  const handleDownload = (paxmlString: any, dates: any) => {
    const file = new Blob([paxmlString], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(file, `${dates.fromDate}_${dates.toDate}.paxml`);
  };
  const download = () => {
    setIsLoadingDownload(true);
    const dates = getPaxmlTimesForMonth();
    Api()
      .admin.getPaxml(parseInt(dates.fromDate), parseInt(dates.toDate))
      .then((res) => {
        handleDownload(res.data, dates);
      })
      .finally(() => setIsLoadingDownload(false));
  };

  return (
    <LoggedInPageContainer>
      <H3>Start</H3>
      <H4>Example of potential info boxes</H4>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <BoxStyled>20 new companies</BoxStyled>
        </Grid>
        <Grid item xs={12} md={4}>
          <BoxStyled>150 new gigs</BoxStyled>
        </Grid>
        <Grid item xs={12} md={4}>
          <BoxStyled>200 new workers</BoxStyled>
        </Grid>
      </Grid>

      <Box component="section" display="flex" alignItems="center" marginTop={5}>
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          Download PAXML for month (only simflexity pay)
        </Text>
      </Box>
      <Box
        component="section"
        sx={{
          p: 2,
          border: `1px solid ${Color.SeaBlue300}`,
          width: 500,
          borderRadius: "8px",
        }}
        display="flex"
        alignItems="center"
      >
        <input
          type="month"
          id="start"
          name="start"
          value={paxmlMonth}
          style={{ fontSize: 18 }}
          onChange={(e) => setPaxmlMonth(e.currentTarget.value)}
        />
        <ButtonStyled
          onClick={() => {
            download();
          }}
        >
          {isLoadingDownload ? (
            <CircularProgress size={"24px"} color="inherit" />
          ) : (
            "Download"
          )}
        </ButtonStyled>
      </Box>
    </LoggedInPageContainer>
  );
};
