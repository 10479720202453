import firebase from "./firebase.config";

export const getWorkerImg = (workerId: string | undefined): Promise<string> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${workerId}/photo`)
    .getDownloadURL()
    .then((url) => url)
    .catch(() => null);
};

export const deleteWorkerImage = (
  firebaseId: string | undefined
): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${firebaseId}/photo`)
    .delete()
    .catch((e) => new Error(e));
};

export const deleteWorkerVideo = (
  firebaseId: string | undefined
): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${firebaseId}/video`)
    .delete()
    .catch((e) => new Error(e));
};

export const getCompanyImg = (companyId: number): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  return storageRef
    .child(`Company/${companyId}/photo`)
    .getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch(() => null);
};

export const updateCompanyImg = (
  companyId: number,
  file: any
): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Company/${companyId}/photo`)
    .put(file)
    .then((url) => {
      return url;
    })
    .catch(() => null);
};
export const deleteCompanyImg = (companyId: number): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Company/${companyId}/photo`)
    .delete()
    .catch((e) => new Error(e));
};
export const createCompanyUser = async (email: string, password: string) => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const getWorkerVideo = (workerId: string | undefined): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${workerId}/video`)
    .getDownloadURL()
    .then((url) => url)
    .catch(() => null);
};
