import { TimeReportRow } from "../../../../../interfaces/admin";
import { CircularProgress } from "@material-ui/core";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FilterControls } from "./FilterControls";
import React from "react";
import { formatDateString } from "utils/utils";
import { getApprovedBy, THS } from "./styles";

const getStatusColor = (status: string | null) => {
    if (!status) {
        return 'inherit';
    }
    switch (status) {
        case 'Rejected':
            return 'red';
        case 'New':
            return 'blue';
        case 'Approved':
            return 'green';
        case 'Closed':
            return 'orange';
        case 'Submitted':
            return 'purple';
        default:
            return 'inherit';
    }
};

export const RenderTimeReportTable = (
    timeReportRows: TimeReportRow[],
    handleOpenConfirmDialog: (row: any) => void,
    isLoading: boolean,
    status: string,
    setStatus: (status: string) => void,
    setPage: (page: number) => void,
    sortAscending: boolean,
    setSortAscending: (sortAscending: boolean) => void,
    handlePreviousPage: () => void,
    handleNextPage: () => void,
    page: number,
    companyIdentifier: string | number | null,
    setCompanyIdentifier: (companyIdentifier: string | number | null ) => void
) => {
    return (
        <>
            {isLoading && <CircularProgress size={18} color="inherit"/>}
            {!isLoading && (
                <Box>
                    <FilterControls
                        status={status}
                        setStatus={setStatus}
                        setPage={setPage}
                        sortAscending={sortAscending}
                        setSortAscending={setSortAscending}
                        handlePreviousPage={handlePreviousPage}
                        handleNextPage={handleNextPage}
                        page={page}
                        companyIdentifier={companyIdentifier}
                        setCompanyIdentifier={setCompanyIdentifier}
                    />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><span className={THS}>ID</span></TableCell>
                                    <TableCell align="left"><span className={THS}>Company Name (ID)</span></TableCell>
                                    <TableCell align="left"><span className={THS}>Worker Name (ID)</span></TableCell>
                                    <TableCell align="left" style={{ width: '150px' }}><span className={THS}>Start Time</span></TableCell>
                                    <TableCell align="left" style={{ width: '150px' }}><span className={THS}>End Time</span></TableCell>
                                    <TableCell align="left"><span className={THS}>Status</span></TableCell>
                                    <TableCell align="left"><span className={THS}>Resolution</span></TableCell>
                                    <TableCell align="left"><span className={THS}>Approved By (ID)</span></TableCell>
                                    <TableCell align="left" style={{ width: '150px' }}><span className={THS}>Updated At</span></TableCell>
                                    <TableCell align="left"><span className={THS}></span></TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {timeReportRows.map((row) => (
                                    <TableRow key={row.timeReportId}
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left">{row.timeReportId}</TableCell>
                                        <TableCell align="left">{row.companyName} ({row.companyId})</TableCell>
                                        <TableCell align="left">
                                            {row.workerFirstName} {row.workerLastName} ({row.workerId})
                                        </TableCell>
                                        <TableCell align="left">{formatDateString(row?.startTime)}</TableCell>
                                        <TableCell align="left">{formatDateString(row?.endTime)}</TableCell>
                                        <TableCell align="left"
                                                   style={{ color: getStatusColor(row.status) }}>{row.status}
                                        </TableCell>
                                        <TableCell align="left">{row.resolution}</TableCell>
                                        <TableCell align="left">{getApprovedBy(row)}</TableCell>
                                        <TableCell align="left">{formatDateString(row?.updatedAt)}</TableCell>
                                        <TableCell align="center">
                                            {(row.status !== 'New') && (
                                                <span onClick={() => handleOpenConfirmDialog(row)}
                                                      style={{ color: "darkorange", cursor: "pointer" }}>
                                                Reset Time
                                            </span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};