import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { MenuItems } from "../menuList/menuItems.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, Text } from "components/Typography/text.styled";
import { DrawerMenuListStyled } from "../menuList/drawerMenuList.styled";
import { DrawerStyled } from "./drawer.styled";
import { NavLi, NavIconLi } from "../menuList/navBarMenuList.styled";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Drawer: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();
  const menuList = getMenuList();

  const handleClick = async () => {
    authActions.signOut().then(() => {
      history.push("/company/login");
      setOpen(!open);
    });
  };

  return (
    <DrawerStyled open={open} className="drawer">
      <CustomIcon
        name="cross"
        size="40px"
        onClick={() => {
          setOpen(!open);
        }}
        style={{ margin: "20px 20px 0 0", alignSelf: "flex-end" }}
      />

      <DrawerMenuListStyled>
        <MenuItems menuList={menuList} open={open} setOpen={setOpen} />
        {authState.signedIn && (
          <NavIconLi active={false}>
            <CustomLink color={Color.BurntSienna} onClick={handleClick}>
              <CustomIcon name="logout" padding="5px" />
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                style={{ margin: 0 }}
              >
                {t("ProfileCompany.SignOut")}
              </Text>
            </CustomLink>
          </NavIconLi>
        )}
        {!authState.signedIn && (
          <NavLi active={false}>
            <CustomLink to={`${path}/login`} color={Color.Destructive}>
              {t("LoginCompany.Login")}
            </CustomLink>
          </NavLi>
        )}
      </DrawerMenuListStyled>
    </DrawerStyled>
  );
};
