import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import firebase from "services/firebase/firebase.config";
import { FormStyled } from "./loginForm.styled";
import { CircularProgress } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { InputContainer, LabelStyled, TextInput } from "components/form/inputs/inputs.styled";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { useAuthStore } from "stores/authStore/authStore";

type Props = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const LoginForm: React.FC<Props> = ({ email, setEmail }) => {
  const { t } = useTranslation();
  const [authState, , authDispatch] = useAuthStore();
  const [password, setPassword] = useState("");
  const [ableToLogIn, setAbleToLogIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (email && password) {
      setAbleToLogIn(true);
    } else {
      setAbleToLogIn(false);
    }
  }, [email, password]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (ableToLogIn) {
      authDispatch({ type: "SET_IS_LOADING", payload: true });
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(res => {
          console.log(res.user);
          authDispatch({
            type: "ACCOUNT_SIGN_IN",
            payload: {
              firestoreUser: res.user
            }
          })
        })
        .catch((err) => {
          setErrorMessage(true);
          authDispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  };

  return (
    <>
      {authState.signedIn && <Redirect to="./start" />}
      <FormStyled onSubmit={handleLogin}>
        <InputContainer>
          <LabelStyled>{t("LoginCompany.Email")}</LabelStyled>
          <TextInput value={email} onChange={(e) => setEmail(e.target.value)} />
        </InputContainer>
        <InputContainer>
          <LabelStyled>{t("LoginCompany.Password")}</LabelStyled>
          <TextInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        {errorMessage && (
          <Text color={Color.Destructive} fontSize={FontSize.Small}>
            {t("LoginCompany.CouldNotLogin")}
          </Text>
        )}
        <ButtonStyled
          style={{ marginTop: "40px" }}
          disabled={!ableToLogIn}
          icon={authState.isLoading}
        >
          {authState.isLoading ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            t("LoginCompany.Login")
          )}
        </ButtonStyled>
      </FormStyled>
    </>
  );
};

