import { Color } from "config/colors";
import { FontFamily } from "config/font";
import styled from "styled-components";


export const BoxStyled = styled.div`
  padding: 10px;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: ${FontFamily.MontserratSemiBold};
  box-shadow: 5px 5px 15px 5px ${Color.BurntSienna};
  @media (max-width: 800px) {
    width: 90%;
    margin: 5%;
  }
`;
