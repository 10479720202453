import React, { useState, useEffect } from "react";
import { Api } from "services/api/api.service";
import {
    Modal,
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    MenuItem,
    CircularProgress,
    FormGroup,
    FormLabel, FormControlLabel, Checkbox
} from '@mui/material';
import { createCompanyUser, setEmailNotifications } from "services/api/admin/admin.service";
import { UserData, UserModalProps } from "interfaces/admin"
import { CompanyEmailNotifications } from "utils/enums";

const UserModal: React.FC<UserModalProps> = ({ open, onClose, onSave, user, companyId }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ loadingText, setLoadingText ] = useState("");
    const [ emailNotification, setEmailNotification ] = useState("");

    const [ formData, setFormData ] = useState({
        id: user ? user.id : "",
        firstName: "",
        lastName: "",
        email: "",
        role: 2, // Default to Admin
        emailNotifications: 0 // Default to None
    });

    const [ roleTypes, setRoleTypes ] = useState([
        { value: 1, label: "Owner" },
        { value: 2, label: "Admin" },
        { value: 4, label: "Stamp Clock" },
    ]);

    const getCompanyRoles = (user: UserData) => {
        const role = user?.role;
        switch (role) {
            case "Owner":
                return 1;
            case "Admin":
                return 2;
            case "CompanyTimeStamp":
                return 4;
            default:
                return 2; // Default to Admin
        }
    }

    useEffect(() => {
        if (user) {
            setFormData({
                ...user,
                role: getCompanyRoles(user),
                emailNotifications: saveEmailNotification(user),
            });
        } else {
            setFormData({
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                role: 2, // Default to Admin
                emailNotifications: 0 // Default to None
            });
        }
    }, [ user ]);

    const saveEmailNotification = (user: any) => {
        const emailNotificationStr = user.emailNotifications;
        switch (emailNotificationStr) {
            case "None":
                return CompanyEmailNotifications.None;
            case "TimeReport":
                return CompanyEmailNotifications.TimeReport;
            case "Application":
                return CompanyEmailNotifications.Application;
            case "All":
                return CompanyEmailNotifications.All;
            default:
                return CompanyEmailNotifications.None; // Default to None
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        setIsLoading(true);
        setLoadingText("Processing user, please wait...");
        await onSave(formData);
        setIsLoading(false);
        onClose();
        setFormData({
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            role: 2, // Default to Admin
            emailNotifications: 0 // Default to None
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    const handleEmailNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setFormData({ ...formData, emailNotifications: value });
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                {isLoading ? (
                    <>
                        <CircularProgress/>
                        <Typography>{loadingText}</Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" style={{ marginBottom: 20 }}>{user ? "Edit User" : "Add New User"}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} key="firstName">
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} key="lastName">
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} key="email">
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                    disabled={!!user} // Disable if editing an existing user, due to firebase id requirements
                                />
                            </Grid>
                            <Grid item xs={12} key="role">
                                <TextField
                                    select
                                    label="Company Role"
                                    name="role"
                                    value={formData.role}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {roleTypes.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} key="email-notifications-key">
                                <TextField
                                    select
                                    label="Email Notifications"
                                    name="emailNotifications" // Corrected name attribute
                                    value={formData.emailNotifications}
                                    onChange={handleEmailNotificationChange}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>None</MenuItem>
                                    <MenuItem key={1} value={1}>TimeReport</MenuItem>
                                    <MenuItem key={2} value={2}>Application</MenuItem>
                                    <MenuItem key={3} value={3}>All</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} key="saveButton">
                                <Button onClick={handleSave} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>
        </Modal>
    );
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

export default UserModal;