import { Box } from "@mui/system";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from 'react';
import { THS } from './styles';
import { WithdrawalRow } from "interfaces/admin";
import { formatDateString } from "utils/utils";
import { Button } from "@material-ui/core";
import { ConfirmModal } from './ConfirmModal';

interface WithdrawalTableProps {
    withdrawals: WithdrawalRow[];
    handleSetToFailed: (workerId: number, withdrawalId: number, status: string) => void;
    setStatus: (status: string) => void;
}

const getStatusColor = (status: string | null) => {
    if (!status) {
        return 'inherit';
    }
    switch (status) {
        case 'Failed':
            return 'red';
        case 'Paid':
            return 'green';
        case 'Created':
            return 'blue';
        default:
            return 'inherit';
    }
};

const convertYearMonth = (yearMonth: string | undefined) => {
    if (yearMonth === undefined) {
        return '';
    }

    if (yearMonth.length !== 6) {
        return yearMonth;
    }

    const year = yearMonth.slice(0, 4);
    const month = yearMonth.slice(4, 6);
    return `${year}-${month}`;
}

export const WithdrawalTable: React.FC<WithdrawalTableProps> = ({ withdrawals, handleSetToFailed, setStatus }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState<WithdrawalRow | null>(null);

    const handleOpenModal = (withdrawal: WithdrawalRow) => {
        setSelectedWithdrawal(withdrawal);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedWithdrawal(null);
    };

    const handleConfirm = () => {
        if (selectedWithdrawal) {
            handleSetToFailed(selectedWithdrawal.workerId, selectedWithdrawal.withdrawalId, 'Failed');
        }
        handleCloseModal();
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><span className={THS}>ID</span></TableCell>
                            <TableCell align="left"><span className={THS}>Worker ID</span></TableCell>
                            <TableCell align="left"><span className={THS}>Full Name</span></TableCell>
                            <TableCell align="left"><span className={THS}>Net Amount</span></TableCell>
                            <TableCell align="left"><span className={THS}>Gross Amount</span></TableCell>
                            <TableCell align="left"><span className={THS}>Type</span></TableCell>
                            <TableCell align="left"><span className={THS}>Period</span></TableCell>
                            <TableCell align="left"><span className={THS}>Date</span></TableCell>
                            <TableCell align="left"><span className={THS}>Status</span></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {withdrawals.map((withdrawal) => (
                            <TableRow key={withdrawal.withdrawalId}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{withdrawal.withdrawalId}</TableCell>
                                <TableCell align="left">{withdrawal.workerId}</TableCell>
                                <TableCell align="left">{withdrawal.firstName} {withdrawal.lastName}</TableCell>
                                <TableCell align="left">{withdrawal.netAmount}</TableCell>
                                <TableCell align="left">{withdrawal.grossAmount}</TableCell>
                                <TableCell align="left">{withdrawal.withdrawalType}</TableCell>
                                <TableCell align="left">{convertYearMonth(withdrawal.period)}</TableCell>
                                <TableCell align="left">{formatDateString(withdrawal.withdrawalDate)}</TableCell>
                                <TableCell align="left"
                                        style={{ color: getStatusColor(withdrawal.withdrawalStatus)}}>
                                    {withdrawal.withdrawalStatus}
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        withdrawal.withdrawalStatus === 'Created'
                                            ? <Button
                                                onClick={() => handleOpenModal(withdrawal)}
                                                color="secondary">
                                                Set To Failed
                                            </Button>
                                            : null
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmModal
                open={modalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirm}
                withdrawal={selectedWithdrawal}
            />
        </Box>
    );
};