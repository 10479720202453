import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";

type InputProps = {
  disabled?: boolean;
  fontSize?: FontSize;
};

export const InputContainer = styled.div`
  margin: 20px 0;
  display: block;
  width: 100%;
`;

export const TextInput = styled.input<{ fontSize?: FontSize }>`
  border: none;
  border-bottom: 1px solid ${Color.SeaBlue400};
  width: 100%;
  padding: 5px;
  outline: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  color: ${(props) => (props.disabled ? Color.SeaBlue600 : Color.MidnightBlue)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  border-radius: 0px;

  ::placeholder {
    color: ${Color.SeaBlue400};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export const LabelStyled = styled.span<{
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
}>`
  color: ${(props) => (props.color ? props.color : Color.SeaBlue600)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
`;

export const NumberInput = styled.input<{
  fontSize?: FontSize;
  fontFamily?: FontFamily;
}>`
  -moz-appearance: textfield; /* Firefox */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  width: 50px;
  padding: 10px;
  background-color: ${Color.SeaBlue200};
  border: none;
  border-radius: 8px;
  color: ${Color.MidnightBlue};
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  outline: none;
  font-family: ${(props) =>
      props.fontFamily
        ? `${props.fontFamily}, Helvetica`
        : FontFamily.MontserratBold},
    Helvetica;

  &:focus {
    border: 1px solid ${Color.BurntSienna};
    background-color: rgba(45, 138, 91, 0.2);
    color: ${Color.BurntSienna};
  }
  ::placeholder {
    color: ${Color.SeaBlue400};
  }
`;

export const TextArea = styled.textarea<InputProps>`
  width: 100%;
  border: none;
  padding: 5px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  border-bottom: 1px solid ${Color.SeaBlue400};
  margin: 20px 0;
  resize: none;
  border-radius: 0px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.SeaBlue400};
  }
`;

export const IconInput = styled.input`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  z-index: 5;
  border: 2px solid white;

  &:hover {
    cursor: pointer;
  }
`;

export const LabelFileInput = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 48px;
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
`;

export const CheckBox = styled.div`
  flexdirection: row;
  alignitems: center;
  width: 35px;
  height: 35px;
  margintop: 32;
  border: 2px solid ${Color.BurntSienna};
  border-radius: 8px;
`;
