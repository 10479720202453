let apiHost: string;
let environment: string;

const hostname = window && window.location && window.location.hostname;
// Production
if (hostname === "admin.simflexity.com") {
  apiHost = "https://api.simflexity.com";
  environment = "production";
}
// Staging
else if (hostname === "admin-stage.simflexity.com") {
  apiHost = "https://api-stage.simflexity.com";
  environment = "staging";
}
// Dev
else {
  // apiHost = "https://simflexity-api-dev.azurewebsites.net";
  // New dev api host
  apiHost = "https://api-dev.simflexity.com";
  environment = "development";
}
export const API_ROOT = `${apiHost}/api`;
export const ENVIRONMENT = environment;
export const API_ADMIN_ROOT = `${API_ROOT}/Admin`;
export const PAXML_ROOT =
  "https://simflexitypaxml.azurewebsites.net/api/PaxmlHttpTrigger";
