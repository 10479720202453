import { FeatureConfiguration } from "model/FeatureConfiguration";
import { API_ROOT } from "services/baseURLs.config";
import { admin } from "./admin/admin.service";

const getFeatureConfiguration = async() => {
  try {
    // const token = await firebase.auth().currentUser?.getIdToken();
    const result = await fetch(`${API_ROOT}/VersionInformation`);
    if (!result.ok) {
      throw new Error(result.statusText);
    }
    const data: FeatureConfiguration[] = await result.json();
    return data;
  } catch (error) {
    
  }
};

export const Api = () => ({
  admin,
  getFeatureConfiguration
});
