import React, { useState, useRef } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { Api } from 'services/api/api.service';
import { WorkerDto } from "model/Worker";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

interface SearchComponentProps {
    onSearch: (results: WorkerDto[], page: number) => void;
    page: number;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onSearch, page }) => {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const firstNameRef = useRef<HTMLInputElement>(null);

    const handleSearch = async () => {
        try {
            const results = await Api().admin.getWorkers(page, firstName, lastName);
            onSearch(results, page);
        } catch (error) {
            console.error('Error searching workers:', error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            setFirstName('');
            setLastName('');
            handleSearch();
            firstNameRef.current?.focus();
        }
    };

    return (
        <fieldset style={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px' }}>
            <legend style={{ padding: '0 10px', fontSize: '1.5em' }}>Search Workers</legend>
            <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        inputRef={firstNameRef}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <Box style={{
                        border: '1px solid #ccc',
                        padding: '16px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography variant="body1" style={{ color: 'gray', fontStyle: 'italic' }}>
                            Type the first and/or last name of the worker you are looking for and press Enter.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </fieldset>
    );
};

export default SearchComponent;