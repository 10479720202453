import React from 'react';
import { Box, TextField, MenuItem, Button } from '@mui/material';

interface FilterComponentProps {
    workerId: string;
    setWorkerId: (id: string) => void;
    status: string;
    setStatus: (status: string) => void;
    page: number;
    setPage: (page: number) => void;
    withdrawalCount: number;
}

const statuses = [
    { value: 'All', label: 'All' },
    { value: 'Created', label: 'Created' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Paid', label: 'Paid' },
    { value: 'Undefined', label: 'Undefined' },
];

export const FilterComponent: React.FC<FilterComponentProps> = ({ workerId, setWorkerId, status, setStatus, page, setPage, withdrawalCount }) => {
    const handleWorkerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkerId(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2, justifyContent: 'flex-end' }}>
            <TextField
                label="Worker ID"
                value={workerId}
                onChange={handleWorkerIdChange}
                variant="outlined"
                size="small"
            />
            <TextField
                select
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                variant="outlined"
                size="small"
            >
                {statuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Button
                variant="contained"
                onClick={() => setPage(page - 1)}
                disabled={page === 0}
            >
                Previous Page
            </Button>
            <Button
                variant="contained"
                onClick={() => setPage(page + 1)}
                disabled={withdrawalCount === 0}
            >
                Next Page
            </Button>
        </Box>
    );
};