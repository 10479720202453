import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import se from "./lang/se.json";
import enRoles from "./lang/en.roles.json";
import seRoles from "./lang/se.roles.json";
import enSkills from "./lang/en.skills.json";
import seSkills from "./lang/se.skills.json";

const resources = {
  en: {
    translation: en,
    roles: enRoles,
    skills: enSkills,
  },
  sv: {
    translation: se,
    roles: seRoles,
    skills: seSkills,
  },
};

i18n.use(initReactI18next).init(
  {
    lng: navigator.languages[0],
    fallbackLng: "en",
    defaultNS: "translation",
    resources: resources,
    react: {
      useSuspense: false,
    },
    interpolation: { escapeValue: false },
  },
  (err, t) => {
    if (err) {
      return console.log("something went wrong loading", err);
    }
    t("key"); // -> same as i18next.t
  }
);

export default i18n;
