import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoggedOutPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3 } from "components/Typography/text.styled";
import { LoginForm } from "web-apps/admin/components/login/loginForm.component";

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <LoggedOutPageContainer>
      <H3>{t("LoginCompany.Title")}</H3>
      <LoginForm email={email} setEmail={setEmail} />
    </LoggedOutPageContainer>
  );
};
