import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import {
  GlobalActions,
  GlobalStoreState,
  globalStoreReducer,
  initGlobalStoreState,
} from "./globalStore.reducer";

export type GlobalStoreDispatch = (action: GlobalActions) => void;

const GlobalStateContext = createContext<GlobalStoreState | undefined>(
  undefined
);
const GlobalDispatchContext = createContext<GlobalStoreDispatch | undefined>(
  undefined
);

export const GlobalStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    globalStoreReducer,
    initGlobalStoreState
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStore = () => {
  const store = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  if (store === undefined || dispatch === undefined) {
    throw new Error(
      "useGlobalStore must be used within a GlobalStoreProvider"
    );
  }

  return [store, dispatch] as const;
};
