import styled from "styled-components";

export const LoggedOutPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoggedInPageContainer = styled.div`
  width: 100%;
  display: block;
  max-width: 1100px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 0 10px;

  @media (min-width: 768px) {
    width: 90vw;
    margin-top: 150px;
  }
`;

export const CalendarContainer = styled(LoggedInPageContainer)`
  max-width: 1200px;
`;

export const ChatLayout = styled.div`
  width: 100%;
  height 100vh;
  display: block;
  max-width: 700px;
  margin: auto;
  padding: 100px 10px 30px 10px;

  @media (min-width: 768px) {
    width: 90vw;
  }
`;
