import { Redirect, Route, Switch } from "react-router-dom";
import "./services/translation/i18n";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { AdminApp } from "web-apps/admin/admin.app";
import { AuthStoreProvider } from "stores/authStore/authStore";
import { GlobalStoreProvider } from "stores/globalStore/globalStore";


function App() {
  return (
    <GlobalStoreProvider>
      <AuthStoreProvider>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="admin/login" />}
          />
          <Route path="/admin">
            <AdminApp />
          </Route>
        </Switch>
      </AuthStoreProvider>
    </GlobalStoreProvider>
  );
}

export default App;
