import firebase from "services/firebase/firebase.config";
import { AuthStoreDispatch } from "./authStore";
import { authState } from "./authStore.reducer";


const signOut = (dispatch: AuthStoreDispatch) => async (): Promise<void> => {
  dispatch({ type: "SET_IS_LOADING", payload: true });

  return firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: "ACCOUNT_SIGN_OUT" });
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.reject();
    });
};

export const AuthActions = (dispatch: AuthStoreDispatch, state: authState) => ({
  signOut: signOut(dispatch),
});
