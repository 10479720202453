import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import { CompanyRowData } from "../../../../model/Company";
import { Api } from "services/api/api.service";
import { TextField, Grid, Paper } from '@mui/material';
import UserModal from "./user-modal-component";
import { UserData } from "interfaces/admin"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export const CompaniesEditUsers: React.FC<{ data: CompanyRowData, onClose: () => void }> = ({ data, onClose }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ loadingText, setLoadingText ] = useState("");
    const [ error, setError ] = useState<any | null>(null);
    const [ open, setOpen ] = useState(false);
    const [ confirmOpen, setConfirmOpen ] = useState(false);
    const [ selectedUserId, setSelectedUserId ] = useState<number | null>(null);
    const [ users, setUsers ] = useState<UserData[]>([]);
    const [ userModalOpen, setUserModalOpen ] = useState(false);
    const [ currentUser, setCurrentUser ] = useState<UserData | null>(null);
    const [ companyId, setCompanyId ] = useState<any | null>(null);

    const handleCloseError = () => {
        setError(null);
        fetchUsers();
    }

    const handleOpen = () => {
        fetchUsers();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        onClose(); // Call the callback function to reload data
    };

    const fetchUsers = async () => {
        let users: UserData[] = [];
        if (companyId) {
            setIsLoading(true);
            setLoadingText("Fetching user, please wait...");

            try {
                users = await Api().admin.getCompanyUsersById(companyId);
            } catch (error) {
                console.error("Error fetching users", error);
            }
            setIsLoading(false);
            setUsers(users);
        }
    };

    useEffect(() => {
        setCompanyId(data.id);
        fetchUsers();
    }, [ data.id ]);

    const handleDeleteClick = (userId: number) => {
        setSelectedUserId(userId);
        setConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        if (selectedUserId) {
            try {
                await Api().admin.deleteCompanyUser(selectedUserId, companyId);
            } catch (error) {
                const msg = 'Delete User, an unknown error occurred'
                if (error instanceof Error) {
                    setError(error.message || msg);
                } else {
                    setError(msg);
                }
            } finally {
                setIsLoading(false);
            }

            setUsers(users.filter(user => user.id !== selectedUserId));
        }
        setConfirmOpen(false);
        setSelectedUserId(null);
    };

    const handleUserModalOpen = (user?: UserData) => {
        setCurrentUser(user || null);
        setUserModalOpen(true);
    };

    const handleUserModalClose = () => {
        setUserModalOpen(false);
    };

    const handleUserSave = async (user: UserData) => {
        if (currentUser) {
            // Edit existing user
            try {
                await Api().admin.updateCompanyUser(companyId, user)
            } catch (error: any) {
                setError(error.message || 'An error occurred');
            } finally {
                fetchUsers(); // Reload data from the API
                setUsers(users.map(u => (u.id === user.id ? user : u)));
            }
        } else {
            // Add new user
            try {
                await Api().admin.createCompanyUser(companyId, user);
            } catch (error: any) {
                setError(error.message || 'An error occurred');
            } finally {
                fetchUsers(); // Reload data from the API
            }
        }
        setUserModalOpen(false);
    };

    const handleUserRole = (role: string | number) => {
        switch (role) {
            case "CompanyOwner":
            case 1:
                return "Owner";
            case "CompanyAdmin":
            case 2:
                return "Admin";
            case "CompanyTimeStamp":
            case 4:
                return "Stamp Clock";
            default:
                return null;
        }
    };

    return (
        <div>
            <span onClick={handleOpen} style={{ color: "blue", cursor: "pointer" }}>
                Users
            </span>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Users for Company: {data.companyName}
                    </Typography>
                    {isLoading ? (
                        <>
                            <CircularProgress/>
                            <Typography>{loadingText}</Typography>
                        </>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Email Settings</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.firstName}</TableCell>
                                            <TableCell>{user.lastName}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{handleUserRole(user?.role)}</TableCell>
                                            <TableCell>{user.emailNotifications}</TableCell>
                                            <TableCell>
                                                <Button size="small" variant="contained" color="primary"
                                                        onClick={() => handleUserModalOpen(user)}>Edit</Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button size="small" variant="contained" color="warning"
                                                        onClick={() => handleDeleteClick(user.id)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <Grid container spacing={1} justifyContent="flex-end" style={{ marginTop: 13 }}>
                        <Grid item xs>
                            <Button onClick={handleClose} variant="contained" size="medium">Close</Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button onClick={() => handleUserModalOpen()} variant="contained" color="success"
                                    size="medium">New User</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <UserModal
                open={userModalOpen}
                onClose={handleUserModalClose}
                onSave={handleUserSave}
                user={currentUser}
                companyId={companyId}
            />

            <Dialog
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {isLoading && <CircularProgress/>}

            <Dialog open={!!error} onClose={() => handleCloseError()}>
                <DialogTitle>Server Message</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: "red" }}>
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseError()}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

