import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from "@material-ui/core";

interface ErrorDialogProps {
    errorOpen: boolean;
    setErrorOpen: (open: boolean) => void;
    error: string;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
     errorOpen,
     setErrorOpen,
     error
 }) => {
    return (
        <Dialog
            open={errorOpen}
            onClose={() => setErrorOpen(false)}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">{"Server Error"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    {error}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setErrorOpen(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
