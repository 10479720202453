import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";

import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { MenuItems } from "../menuList/menuItems.component";
import { CustomIcon } from "components/icon/customIcon.component";

import { CustomLink, Text } from "components/Typography/text.styled";
import {
  Burger,
  NavBarStyled,
  LogInWrapper,
  Flex1,
} from "./navBar.styled";
import { NavBarMenuListStyled } from "../menuList/navBarMenuList.styled";
import LogoSVG from "images/logo/svg/simflexity-logo.svg";
import { Logo } from "../logo/logo.styled";
import { Badge } from "components/buttons/buttons.styled";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavBar: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();
  const menuList = getMenuList();
  const history = useHistory();
  const handleClick = async () => {
    authActions.signOut().then(() => {
      history.push("/admin/login");
      setOpen(!open);
    });
  };

  return (
    <NavBarStyled>
      <Logo src={LogoSVG} alt="logo" />
      {authState.signedIn && 
        <Flex1>
          <NavBarMenuListStyled>
            <MenuItems menuList={menuList} open={open} setOpen={setOpen} />
          </NavBarMenuListStyled>
        </Flex1>
      }
      <LogInWrapper>
        {authState.signedIn ? (
          <CustomLink color={Color.MidnightBlue} onClick={handleClick}>
            {t("ProfileCompany.SignOut")}
          </CustomLink>
        ) : (
          <CustomLink color={Color.Destructive} onClick={() => history.push("/admin/login")}>
            {t("LoginCompany.Login")}
          </CustomLink>
        )}
      </LogInWrapper>
      <Burger>
        <CustomIcon
          name="hamburger"
          size="40px"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </Burger>
    </NavBarStyled>
  );
};
