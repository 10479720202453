import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Link
} from '@mui/material';
import InstructionsModal from "./InstructionsModal";

interface RenderControlsProps {
    status: string;
    setStatus: (status: string) => void;
    setPage: (page: number) => void;
    sortAscending: boolean;
    setSortAscending: (sortAscending: boolean) => void;
    handlePreviousPage: () => void;
    handleNextPage: () => void;
    page: number;
    companyIdentifier: string | number | null;
    setCompanyIdentifier: (companyName: string | number | null) => void;
}

export const FilterControls: React.FC<RenderControlsProps> = ({
    status,
    setStatus,
    setPage,
    sortAscending,
    setSortAscending,
    handlePreviousPage,
    handleNextPage,
    page,
    companyIdentifier,
    setCompanyIdentifier
}) => {
    const [inputValue, setInputValue] = useState<string | number | null>(companyIdentifier);
    const [modalOpen, setModalOpen] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setCompanyIdentifier(inputValue);
            setPage(page);
        }
    };

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', marginBottom: 2 }}>
            <Link component="button" variant="body1" onClick={handleOpenModal} sx={{ color: 'gray', fontStyle: 'italic', marginRight: 10 }}>
                Instructions
            </Link>
            <TextField
                label="Company Name or Id"
                variant="outlined"
                value={inputValue ?? ''}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ marginRight: 2 }}
            />
            <FormControl variant="outlined" sx={{ marginRight: 2, minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                    label="Status"
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="New">New</MenuItem>
                    <MenuItem value="Submitted">Submitted</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel
                control={<Checkbox checked={sortAscending} onChange={(e) => setSortAscending(e.target.checked)}/>}
                label="Newest Start Time First"
            />
            <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 20 }}
                onClick={handlePreviousPage}
                disabled={page === 0}
            >
                Previous Page
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleNextPage}
            >
                Next Page
            </Button>
            <InstructionsModal open={modalOpen} handleClose={handleCloseModal} />
        </Box>
    );
};