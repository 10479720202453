import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily } from "config/font";

export const DrawerMenuListStyled = styled.ul`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: ${Color.PitchBlack};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;

  li {
    height: 60px;
  }
  a {
    margin: 0;
  }
`;
