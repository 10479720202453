import styled from "styled-components";
import { Color } from "config/colors";

export const NavBarStyled = styled.nav`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid ${Color.SeaBlue200};
  z-index: 99;
  background-color: white;
`;
export const NavBarInnerStyled = styled.nav`
  max-width: 1100px;
  height: 75px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Burger = styled.div`
  margin: 0 20px 0 15px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const LogInWrapper = styled.li`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin: 0 20px 0 15px;

  @media (min-width: 1000px) {
    display: flex;
  }
`;

export const Flex1 = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;
