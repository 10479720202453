import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

interface InstructionsModalProps {
    open: boolean;
    handleClose: () => void;
}

const InstructionsModal: React.FC<InstructionsModalProps> = ({ open, handleClose }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h6" component="h2">
                    Filter Instructions
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Type a Company Name or Id in the input field to filter the time reports by company.
                    Leave blank to show all time reports. Press Enter to apply the filter.
                    <br/>
                    <br/>
                    To clear the search result, delete the text in the input field and press Enter.
                    <br/>
                    <br/>
                    New data is fetched whenever the status and Start Time columns are updated.
                </Typography>
                <Button onClick={handleClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default InstructionsModal;