import React, { useEffect, useState } from "react";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { WithdrawalTable } from "./components/WithdrawalsTable";
import { H3 } from "components/Typography/text.styled";
import { WithdrawalRow } from "interfaces/admin";
import { Api } from "services/api/api.service";
import { ServerErrorModal } from "./components/ServerErrorModal";
import { FilterComponent } from "./components/FilterComponent";

export const WithdrawalsPage: React.FC = () => {
    const [withdrawals, setWithdrawals] = useState([] as WithdrawalRow[]);
    const [isLoading, setIsLoading] = useState(true);
    const [workerId, setWorkerId] = useState<string>('');
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState<string>('Created');
    const [reload, setReload] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [withdrawalCount, setWithdrawalCount] = useState(0);

    /**
     * For reference, statuses in table WorkerAccountWithdrawals
     * Undefined = 0,
     * Created = 1,
     * Paid = 2,
     * Failed = 3
     */
    const fetchData = async () => {
        try {
            const withdrawals = await Api().admin.getWorkerWithdrawals(status, workerId, page);
            setWithdrawals(withdrawals);
            setWithdrawalCount(withdrawals.length);
        } catch (error: any) {
            setError('Bad request: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetToFailed = async (workerId: number, withdrawalId: number, status: string) => {
        try {
            await Api().admin.setWorkerWithdrawalStatus(workerId, withdrawalId, status);
            setReload(!reload);
        } catch (error: any) {
            setError('Bad request: ' + error.message);
        }
    }

    const handleCloseErrorModal = () => {
        setError(null);
    };

    useEffect(() => {
        fetchData();
    }, [status, workerId, page, reload]);

    return (
        <LoggedInPageContainer>
            <H3>Withdrawals</H3>
            <br/>
            <FilterComponent
                workerId={workerId}
                setWorkerId={setWorkerId}
                status={status}
                setStatus={setStatus}
                page={page}
                setPage={setPage}
                withdrawalCount={withdrawalCount}
            />
            {isLoading
                ? <p>Loading...</p>
                : <WithdrawalTable
                    withdrawals={withdrawals}
                    handleSetToFailed={handleSetToFailed}
                    setStatus={setStatus}
                />
            }
            {error && <ServerErrorModal open={!!error} onClose={handleCloseErrorModal} errorMessage={error} />}
        </LoggedInPageContainer>
    )
}