import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin-top: 5px;
`;

export const VideoPresentation = styled.video`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 10px;
`;
export const ProfileDetailsContainer = styled.div`
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
`;
export const VideoContainer = styled(ProfileDetailsContainer)`
  max-height: unset;
  padding-bottom: 0;
  margin-bottom: 10px;
  margin-top: 10px;
`;
export const LabelStyled = styled.span<{
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
}>`
  color: ${(props) => (props.color ? props.color : Color.SeaBlue600)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
`;
