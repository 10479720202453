import React from "react";
import { Route, Redirect, useRouteMatch, RouteProps } from "react-router-dom";
import { useAuthStore } from "../../../../stores/authStore/authStore";
export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const [authState] = useAuthStore();
  const { path } = useRouteMatch();
  const {
    render,
    children: Children,
    component: Component,
    ...unAuthProps
  } = props;

  return (
    <>
      {authState.signedIn ? (
        <Route {...props} />
      ) : (
        <Route
          {...unAuthProps}
          render={() => <Redirect to={`${path}/login`} />}
        />
      )}
    </>
  );
};

export const PublicRoute: React.FC<RouteProps> = (props) => {
  return <Route {...props} />;
};
