import React, { useEffect, useState } from "react";
import { H3 } from "components/Typography/text.styled";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { useAuthStore } from "stores/authStore/authStore";
import { Api } from "services/api/api.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { CompanyGroup } from "model/CompanyGroup";
import { CircularProgress, Tab, Tabs, TextField } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Backdrop, StyledModal } from "../../components/shared/modal.styled";
import { Box } from '@mui/system';
import { FontFamily } from "config/font";
import { GroupCompany, GroupCompanyT } from "model/GroupCompany";
import { CustomIcon } from "components/icon/customIcon.component";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CompanyDto } from "model/Company";
import { authState } from "stores/authStore/authStore.reducer";

type CompanyGroupRowData = {
  id?: number | undefined;
  companyName?: string | null;
  registrationNumber?: string | null;
  description?: string | null;
  address?: string | null;
  postalCode?: string | null;
  city?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  contact?: string | null;
  websiteUrl?: string | null;
  referenceCode?: string | null;
}

export const CompanyGroupPage: React.FC = () => {
  const [authState, authActions] = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modalData, setModalData] = useState<CompanyGroup | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [groupCompanies, setGroupCompanies] = useState<GroupCompanyT[] | undefined>([])
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [companyGroups, setCompanyGroups] = useState<CompanyGroup[] | undefined>([]);
  const [companyGroupRows, setCompanyGroupRows] = useState<CompanyGroup[] | undefined>([]);
  const [groupCompanyRows, setGroupCompanyRows] = useState<GroupCompanyT[] | undefined>([]);
  const [verifiedCompanies, setVerifiedCompanies] = useState<CompanyDto[]>([]);
  const [selectableCompanies, setSelectableCompanies] = useState<CompanyDto[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<GroupCompanyT | undefined>(undefined);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  useEffect(() => {
    getInitialData(authState);
  }, [authState])

  function createData(companyGroup: CompanyGroup): CompanyGroupRowData {
    return { 
      id: companyGroup.id, 
      companyName: companyGroup.companyName, 
      registrationNumber: companyGroup.registrationNumber, 
      description: companyGroup.description,
      address: companyGroup.address,
      postalCode: companyGroup.postalCode,
      city: companyGroup.city,
      email: companyGroup.email,
      phoneNumber: companyGroup.phoneNumber,
      contact: companyGroup.contactPerson,
      websiteUrl: companyGroup.websiteUrl,
      referenceCode: companyGroup.referenceCode,
    };
  }

  const getInitialData = (authState: authState) => {
    if (authState.signedIn) {
      setIsLoading(true);
      Api().admin.getCompanyGroups()
        .then()
        .then((res: CompanyGroup[] | undefined) => {
          setCompanyGroups(res);
          const rows = res?.map((companyGroup: CompanyGroup) => createData(companyGroup));
          setCompanyGroupRows(rows);
          setIsLoading(false);
        });
      Api().admin.getCompanies('verified')
        .then(res => setVerifiedCompanies(res?.data));  
    }
  };

  const addNew = () => {
    setModalData({});
    setIsEditing(false);
    setModalOpen(true);

  };

  const editRow = (row: CompanyGroupRowData) => {
    const companyGroupModalData = companyGroups?.find(companyGroup => companyGroup.id === row.id);
    setModalData(companyGroupModalData);

    Api().admin.getGroupCompanies(companyGroupModalData?.id)
      .then(res => {
        const companiesInGroup = res?.map(r => r.company);
        setGroupCompanyRows(companiesInGroup);
        setGroupCompanies(companiesInGroup);
        setSelectableCompanies(verifiedCompanies.filter(company => !companiesInGroup?.find(gc => gc.id === company.id)));

        setModalOpen(true);
        setIsEditing(true);
      })
  }
  
  const handleCompanyChange = (event: SelectChangeEvent) => {
    Api().admin.addCompanyToGroup(+event.target.value, modalData?.id)
      .then(res => {
        setModalOpen(false);
        setIsEditing(false);
        setTabValue(0);
      })
  };

  const useSelectedCompany = (event: SelectChangeEvent) => {
    setSelectedCompanyId(event.target.value);
    const chosenCompany = verifiedCompanies.find(company => company.id === +event.target.value);

    let data: CompanyGroup | undefined = {
      companyName: chosenCompany?.legalName,
      registrationNumber: chosenCompany?.registrationNumber,
      websiteUrl: chosenCompany?.websiteUrl,
      address: chosenCompany?.address,
      city: chosenCompany?.city,
      postalCode: chosenCompany?.postalCode,
      contactPerson: chosenCompany?.contactPerson,
      phoneNumber: chosenCompany?.phoneNumber,
      email: chosenCompany?.email,
      description: chosenCompany?.description
    };
    setModalData(data);
  }

  const renderTable = (rows: CompanyGroupRowData[] | undefined) => {
    return (
      <>
      {isLoading && <CircularProgress size={18} color="inherit" />}
      {!isLoading && (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="right">Company Name</TableCell>
              <TableCell align="right">Reference code</TableCell>
              <TableCell align="right">Registration Number</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Contact Person</TableCell>
              
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: CompanyGroupRowData) => (
              <TableRow
                key={row.registrationNumber}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.companyName}</TableCell>
                <TableCell align="right">{row.referenceCode}</TableCell>
                <TableCell align="right">{row.registrationNumber}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.contact}</TableCell>
                <TableCell align="right"><span onClick={() => editRow(row)} style={{color: 'blue', cursor: 'pointer'}}>Edit</span></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </>
    )
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let modalDataCopy = {...modalData};
    switch(event.target.id) {
      case 'company-name':
        modalDataCopy.companyName = event.target.value;
        break;
      case 'company-registration':
        modalDataCopy.registrationNumber = event.target.value;
        break;
      case 'company-reference':
        modalDataCopy.referenceCode = event.target.value;
        break;
      case 'company-contact':
        modalDataCopy.contactPerson = event.target.value;
        break;
      case 'company-phone':
        modalDataCopy.phoneNumber = event.target.value;
        break;
      case 'company-email':
        modalDataCopy.email = event.target.value;
        break;
      case 'company-address':
        modalDataCopy.address = event.target.value;
        break;
      case 'company-postal':
        modalDataCopy.postalCode = event.target.value;
        break;
      case 'company-email':
        modalDataCopy.email = event.target.value;
        break;     
      case 'company-city':
        modalDataCopy.city = event.target.value;
        break;
      case 'company-website':
        modalDataCopy.websiteUrl = event.target.value;
        break;
      case 'company-description':
        modalDataCopy.description = event.target.value;
        break;   
      default:
        break;
    }
    setModalData(modalDataCopy);
  };

  const saveCompanyGroup = () => {
    setIsSaveLoading(true);
    Api().admin.createCompanyGroup(modalData)
      .then(res => {
        getInitialData(authState);
        setIsSaveLoading(false);
        setModalOpen(false);
      })
  };

  const updateCompanyGroup = () => {
    setIsSaveLoading(true);
    Api().admin.updateCompanyGroup(modalData)
      .then(res => {
        getInitialData(authState);
        setIsSaveLoading(false);
        setModalOpen(false);
      });
  };


  const renderModalInformationTab = (edit: boolean) => {
    return (
      <>
      {!edit && (
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end'}}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="demo-simple-select-standard-label">Use existing company</InputLabel>
          <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedCompanyId}
              onChange={useSelectedCompany}
              label="Use existing company"
            >
              {verifiedCompanies.map(company => <MenuItem key={company.id} value={company.id}>{company.legalName} - {company.registrationNumber}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
      )}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
        <TextField id="company-name" label="Company name" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.companyName || ''} />
        </Grid>
        <Grid item xs={9}/>
        <Grid item xs={3}>
        <TextField id="company-registration" label="Registration Number" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.registrationNumber || ''} />
        </Grid>
        <Grid item xs={3}>
        <TextField id="company-reference" label="Reference Code" variant="outlined" style={{width: '100%'}} value={modalData?.referenceCode || ''} InputProps = {{
          disabled: !edit
        }} />
        </Grid>
        <Grid item xs={6}>
        <TextField id="company-website" label="Website" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.websiteUrl || ''}  />
        </Grid>
        <Grid item xs={3}>
        <TextField id="company-address" label="Address" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.address || ''}  />
        </Grid>
        
        <Grid item xs={3}>
        <TextField id="company-postal" label="Postal code" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.postalCode || ''}  />
        </Grid>
        
        <Grid item xs={6}>
        <TextField id="company-city" label="City" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.city || ''}  />
        </Grid>
        <Grid item xs={3}>
        <TextField id="company-contact" label="Contact" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.contactPerson || ''}  />
        </Grid>
        <Grid item xs={3}>
        <TextField id="company-phone" label="Phone number" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.phoneNumber || ''} />
        </Grid>
        <Grid item xs={6}>
        <TextField id="company-email" label="Email" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.email || ''} />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={8}>
        <TextField id="company-description" label="Description" variant="outlined" style={{width: '100%'}} onChange={handleTextFieldChange} value={modalData?.description ||''} minRows={5} multiline />
        </Grid>
        {edit && (
          <Grid item xs={4} style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end'}}>
          <div>
            <ButtonStyled disabled={isSaveLoading} style={{margin: 0, width: '100%'}} onClick={updateCompanyGroup}>
                {isSaveLoading ? <CircularProgress size={18} color="inherit" /> : 'Update Company group'}
            </ButtonStyled>
          </div>
        </Grid>
        )}
        {!edit && (
          <Grid item xs={4} style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end'}}>
          <div>
            <ButtonStyled disabled={isSaveLoading} style={{margin: 0, width: '100%'}} onClick={saveCompanyGroup}>
                {isSaveLoading ? <CircularProgress size={18} color="inherit" /> : 'Save Company group'}
            </ButtonStyled>
          </div>
        </Grid>
        )}
      </Grid>
      </>
    )
  };

  const renderCompanyInfo = () => {

  };

  const deleteCompanyFromGroup = (companyId: number) => {
    Api().admin.deleteCompanyFromGroup(modalData?.id, companyId)
      .then(res => {
        setModalOpen(false);
        setIsEditing(false);
        setTabValue(0);
      });
  }
  const renderModalData = (edit: boolean) => {
    return (
      <>
        <Tabs value={tabValue} onChange={(e,v) => setTabValue(v)} centered>
          <Tab label="Information" />
          <Tab label="Companies in group" />
        </Tabs>
        
        {tabValue === 0 && renderModalInformationTab(edit)}
        {tabValue === 1 && (
          <>
          <div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end'}}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-standard-label">Select company to add to group</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={''}
                onChange={handleCompanyChange}
                label="Select company to add to group"
              >
                {selectableCompanies.map(company => <MenuItem key={company.id} value={company.id}>{company.legalName} - {company.registrationNumber}</MenuItem>)}
              </Select>
            </FormControl>
            
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{marginTop: 20}}>
            <Grid item xs={5} style={{paddingTop: 0 }}>
              {renderGroupCompaniesTable(groupCompanyRows)}
            </Grid>
            {selectedCompany && (
              <Grid container item xs={7} style={{minHeight: 250}}>
              <Grid item xs={6}>
                <b>Company name:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.legalName}
              </Grid>
              <Grid item xs={6}>
                <b>Registration number:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.registrationNumber}
              </Grid>
              <Grid item xs={6}>
                <b>Reference code:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.referenceCode}
              </Grid>
              <Grid item xs={6}>
                <b>Contact person:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.contactPerson}
              </Grid>
              <Grid item xs={6}>
                <b>Email:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.email}
              </Grid>
              <Grid item xs={6}>
                <b>Phone number:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.phoneNumber}
              </Grid>
              <Grid item xs={6}>
                <b>Address:</b>
              </Grid>
              <Grid item xs={6}>
                {selectedCompany?.address}, {selectedCompany?.postalCode}, {selectedCompany?.city}
              </Grid>
            </Grid>
            )}
          </Grid>
          </>
        )}
      </>
    )
  };

  const renderGroupCompaniesTable = (rows: GroupCompanyT[] | undefined) => {
    return (
      <>
      {
        rows && 
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th"><b>Company</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: GroupCompanyT) => (
              <TableRow
                key={row.id}
              >
                <TableCell scope="row">
                  {row.companyName} - {row.registrationNumber}
                </TableCell>
                <TableCell align="right" style={{cursor: 'pointer'}} onClick={() => deleteCompanyFromGroup(row.id)}>
                  <CustomIcon style={{width: '100%'}}
                    name="trashcan"
                    size="12px"
                  />
                </TableCell>
                <TableCell align="right" style={{cursor: 'pointer'}} onClick={() => setSelectedCompany(row)}>
                  <CustomIcon style={{width: '100%'}}
                    name="chevron-right"
                    size="12px"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
    )
  };


  return (
    <LoggedInPageContainer>
      <H3>Company groups</H3>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
        <ButtonStyled style={{margin: 0}} onClick={addNew}>
          Add new
        </ButtonStyled>
      </div>
      {isLoading && <CircularProgress size={18} color="inherit" />}
      {!isLoading && renderTable(companyGroupRows) }

      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          {renderModalData(isEditing)}
        </Box>
      </StyledModal>
    </LoggedInPageContainer>
  );
};

const style = {
  width: '60vw',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
  fontFamily: FontFamily.MontserratRegular
};