type MenuItem = {
  text: string;
  path: string;
  image: string;
};

export const getMenuList = (): MenuItem[] => {
  return [
    {
      text: "Start",
      path: "/admin/start",
      image: "receptionist",
    },
    {
      text: "Companies",
      path: "/admin/companies",
      image: "company",
    },
    {
      text: "Gigs",
      path: "/admin/gigs",
      image: "chef",
    },
    {
      text: "Company groups",
      path: "/admin/company-groups",
      image: "bank",
    },
    {
        text: "Time reports",
        path: "/admin/time-reports",
        image: "clock",
    },
    {
        text: "Withdrawals",
        path: "/admin/withdrawals",
        image: "cashier",
    },
    {
      text: "Workers",
      path: "/admin/workers",
      image: "person",
    },
    {
      text: "Staffing",
      path: "/admin/staffing",
      image: "person",
    },
  ];
};
export const stringToBoolean = (val: string | undefined) =>
  val === "false" ? false : !!val;

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return date.toLocaleString('sv-SE', options).replace(',', ': ');
};