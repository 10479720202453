import { css } from "@emotion/css";
import { TimeReportRow } from "interfaces/admin";

export const THS = css`
    font-weight: bold;
`;

export const TDS = css`
    padding: 5px;
`;

export const lineBreak = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 1px;
`;

export const getApprovedBy = (row: TimeReportRow) => {
    if (row?.approvedBy) {
        return `${row.approvedByName} (${row.approvedBy})`;
    }

    return 'none';
}
