import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  backgroundColor?: Color;
  color?: Color;
  icon?: boolean;
};

export const ButtonStyled = styled.button<ButtonProps>`
  display: ${(props) => (props.icon ? "flex" : "block")};
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-color: ${(props) =>
    props.disabled
      ? Color.Disabled
      : props.backgroundColor
      ? props.backgroundColor
      : Color.BurntSienna};
  border-radius: 8px;
  border: none;
  ${(props) => props.fullWidth && "width: 100%"};
  padding: 10px 20px;
  font-size: ${FontSize.H4};
  font-family: ${FontFamily.MontserratSemiBold}, Helvetica;
  color: ${(props) =>
    props.disabled
      ? Color.SeaBlue500
      : props.color
      ? props.color
      : Color.White};
  outline: none;
  transition: opacity 0.15s;

  ${(props) =>
    !props.disabled &&
    `&:hover {
      cursor: pointer;
      opacity: 0.6;
    }`}
`;

export const Badge = styled.div`
  background-color: ${Color.Destructive};
  color: ${Color.White};
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: ${FontSize.Small};
  position: absolute;
  top: -3px;
  right: -3px;
  padding: 6px;
`;
