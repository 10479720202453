import { UserData, CompanyEmailData } from '../interfaces/admin';
import { CompanyEmailNotifications } from "./enums";

export const validateUserData = (userData: UserData): { isValid: boolean, errors: string[] } => {
    const errors: string[] = [];

    if (userData.id && !Number.isInteger(Number(userData.id))) {
        errors.push('ID must be an integer.');
    }
    if (!userData.role) {
        errors.push('Role is required.');
    }
    if (!userData.firstName) {
        errors.push('First name is required.');
    }
    if (!userData.lastName) {
        errors.push('Last name is required.');
    }
    if (!userData.email) {
        errors.push('Email is required.');
    } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userData.email)) {
            errors.push('Email format is incorrect.');
        }
    }

    return {
        isValid: errors.length === 0,
        errors
    };
};

export const validateEmailSettings = (emailSettings: CompanyEmailData): { isValid: boolean, errors: string[] } => {
    const errors: string[] = [];

    if (!emailSettings.userId) {
        errors.push('User ID is required.');
    }
    if (!emailSettings.companyId) {
        errors.push('Company ID is required.');
    }
    if (emailSettings.emailSetting === undefined || !(emailSettings.emailSetting in CompanyEmailNotifications)) {
        errors.push('Valid email setting is required.');
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}

export default { validateUserData }