import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3, Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { css } from "@emotion/css";
import { Api } from "services/api/api.service";
import React, { useEffect, useState } from "react";
import { useAuthStore } from "stores/authStore/authStore";
import { WorkerDto } from "model/Worker";
import {
  CircularProgress,
  DialogContent,
  FormControl,
  TextField,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FontFamily } from "config/font";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { ButtonStyled } from "components/buttons/buttons.styled";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  deleteWorkerImage,
  deleteWorkerVideo,
  getWorkerImg,
} from "services/firebase/firebase.service";
import person from "../../../../images/ic_person_grey.svg";
import { getWorkerVideo } from "../../../../services/firebase/firebase.service";
import {
  LabelStyled,
  VideoContainer,
  VideoPresentation,
  VideoWrapper,
} from "../../../../components/video/video.styled";
import SearchComponent from './components/SearchComponent';

type WorkerEndorsementResponseData = {
  skill: string;
  endorsedBy: endorsedBy[];
  count: number;
};
type endorsedBy = {
  id: number;
  companyId: string;
  name: string;
};


export const WorkersPage: React.FC = () => {
  const [authState, authActions] = useAuthStore();
  const [disableEnableModalOpen, setDisableEnableModalOpen] = useState(false);
  const [deleteProfilePicModalOpen, setDeleteProfilePicModalOpen] =
    useState(false);
  const [deleteVideoModalOpen, setDeleteVideoModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [workerVideo, setWorkerVideo] = useState<string | null>(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [deleteImageActive, setDeleteImageActive] = useState(false);
  const [deleteVideoActive, setDeleteVideoActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [workers, setWorkers] = useState<WorkerDto[]>([]);
  const [modalData, setModalData] = useState<WorkerDto>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [workerPageNumber, setWorkerPageNumber] = useState(0);
  const [workerEndorsements, setWorkerEndorsements] = useState<
    WorkerEndorsementResponseData[]
  >([]);
  const [workerEndorsementModalOpen, setWorkerEndorsementModalOpen] =
    useState(false);
  const [createWorkerEndorsement, setCreateWorkerEndorsement] = useState(false);
  const [workerEndorsementRequestSucceed, setWorkerEndorsementRequestSucceed] =
    useState(false);
  const [workerEndorsementRequestFailed, setWorkerEndorsementRequestFailed] =
    useState(false);
  const [endorsementCompanyId, setEndorsementCompanyId] = useState<
    string | number
  >("");
  const [endorsementGigId, setEndorsementGigId] = useState<string | number>("");
  const [workerEndorsement, setWorkerEndorsement] = useState<string>("");
  const [workerEndorsementBy, setWorkerEndorsementBy] = useState<any>();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [currentPool, setCurrentPool] = useState<{
    name: string;
    id: number;
  } | null>(null);
  const [staffingIsLoading, setStaffingIsLoading] = useState(false);
  const [staffingPools, setStaffingPools] = useState<
    { id: number; name: string }[]
  >([]);
  const [addToPoolModalOpen, setAddToPoolModalOpen] = useState(false);

  const removeFromPool = () => {
    if (currentPool && modalData?.id) {
      setStaffingIsLoading(true);
      Api()
        .admin.deleteWorkerFromStaffingPool(currentPool?.id, modalData.id)
        .then(() => {
          setShowRemoveModal(false);
          setModalData({
            ...modalData,
            staffingPools:
              modalData?.staffingPools &&
              modalData?.staffingPools.filter(
                (pool) => pool.id !== currentPool.id
              ),
          });
          setStaffingIsLoading(false);
          setCurrentPool(null);
        })
        .catch((err) => {
          console.log("ERROR REMOVING WORKER FROM STAFFING POOL", err);
          setStaffingIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (modalData) {
      Api()
        .admin.getStaffingPools()
        .then((res) => {
          setStaffingPools(res);
        });
    }
  }, [modalData]);

  const addWorkerToPool = () => {
    if (currentPool && modalData.id) {
      console.log(currentPool, modalData);
      setStaffingIsLoading(true);
      Api()
        .admin.addWorkerToStaffingPool(modalData.id, currentPool.id)
        .then((res) => {
          modalData.staffingPools &&
            setModalData({
              ...modalData,
              staffingPools: [...modalData.staffingPools, currentPool],
            });
          setStaffingIsLoading(false);
          setCurrentPool(null);
          setAddToPoolModalOpen(false);
        })
        .catch((err) => {
          console.log("ERROR ADDING WORKER TO STAFFING POOL", err);
          setStaffingIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (authState.signedIn) {
      getInitialData();
    }
  }, [authState]);

  const getInitialData = () => {
    if (authState.signedIn) {
      setIsLoading(true);
      Api()
        .admin.getWorkers(0)
        .then((res) => {
          setWorkers(res);
          setIsLoading(false);
        });
    }
  };

  const loadMoreWorkers = () => {
    const newPageNumber = workerPageNumber + 1;
    setWorkerPageNumber(newPageNumber);
    Api()
        .admin.getWorkers(newPageNumber)
        .then((res) => {
          setWorkers([...workers, ...res]);
          handleSearchResults([...workers, ...res], newPageNumber);
        });
  };

  const getWorkerEndorsements = (id: number | undefined) => {
    Api()
      .admin.getWorkerEndorsements(id)
      .then((res) => {
        let endorsements: WorkerEndorsementResponseData[] = [];
        for (const key in res.endorsements) {
          endorsements.push(res.endorsements[key]);
        }
        setWorkerEndorsements(endorsements);
      });
  };
  const editRow = (worker: WorkerDto) => {
    getWorkerImg(worker.firebaseId)
      .then((url) => {
        setImageUrl(url);
      })
      .catch(() => {});
    getWorkerVideo(worker.firebaseId)
      .then((url) => {
        setWorkerVideo(url);
      })
      .catch(() => {});
    getWorkerEndorsements(worker.id);
    setModalData(worker);
    setIsEditing(true);
    setModalOpen(true);
  };

  const updateWorker = () => {
    setIsSaveLoading(true);
    Api()
      .admin.updateWorker(modalData)
      .then(() => {
        const tempWorkers = workers.map((worker) => {
          if (worker.id === modalData.id) {
            worker = modalData;
            return worker;
          }
          return worker;
        });
        setWorkers(tempWorkers);
        setIsEditing(false);
        setIsSaveLoading(false);
        setModalOpen(false);
      });
  };
  const disableEnableWorker = (
    id: number | undefined,
    status: boolean | undefined
  ) => {
    setIsSaveLoading(true);
    Api()
      .admin.disableEnableWorker(id, status)
      .then(() => {
        workers.map((worker) => {
          if (worker.id === id) {
            worker.active = status ? "True" : "False";
            return worker;
          }
          return worker;
        });
        setIsEditing(false);
        setIsSaveLoading(false);
        setDisableEnableModalOpen(false);
      });
  };
  const handleCreateWorkerEndorsement = (
    workerId: number | undefined,
    skill: string | undefined,
    companyId: number | undefined | string,
    gigId: number | undefined | string
  ) => {
    if (
      workerEndorsements.find(
        (end) =>
          end.skill === skill &&
          end.endorsedBy.find((comp) => comp.companyId === companyId)
      )
    ) {
      setWorkerEndorsementRequestFailed(true);
    } else {
      Api()
        .admin.createWorkerEndorsement(workerId, skill, companyId, gigId)
        .then((res) => {
          if (res.success) {
            getWorkerEndorsements(workerId);
            setWorkerEndorsementRequestSucceed(res.success);
          } else {
            setWorkerEndorsementRequestFailed(true);
          }
        });
    }
  };
  const handleDeleteWorkerEndorsement = (endorsementId: number | undefined) => {
    Api()
      .admin.deleteWorkerEndorsement(endorsementId)
      .then((res) => {
        if (res.success) {
          const currentEndorsement = workerEndorsements.find(
            (endorsement) => endorsement.skill === workerEndorsement
          );
          if (currentEndorsement) {
            const newEndorsedBy = currentEndorsement?.endorsedBy.filter(
              (endorsedBy) => endorsedBy.id !== endorsementId
            );

            if (newEndorsedBy && newEndorsedBy?.length < 1) {
              const newEndorsements = workerEndorsements.filter(
                (end) => end.skill !== workerEndorsement
              );
              if (newEndorsements) {
                setWorkerEndorsements(newEndorsements);
              }
            } else {
              const newEndorsements = workerEndorsements.map((end) =>
                end.skill === workerEndorsement
                  ? { ...end, endorsedBy: newEndorsedBy }
                  : end
              );
              if (newEndorsements) {
                setWorkerEndorsements(newEndorsements);
              }
            }
          }

          setWorkerEndorsementRequestSucceed(res.success);
        } else {
          setWorkerEndorsementRequestFailed(true);
        }
      });
  };

  const handleEnableDisableWorker = (worker: WorkerDto) => {
    setDisableEnableModalOpen(true);
    setModalData(worker);
  };

  const deleteImage = async (firebaseId: any) => {
    return await deleteWorkerImage(firebaseId);
  };

  const deleteVideo = async (firebaseId: any) => {
    return await deleteWorkerVideo(firebaseId);
  };

  const handleSearchResults = (results: WorkerDto[], page: number) => {
    setWorkers(results);
    setWorkerPageNumber(page);
  };

  // Add a state variable to control the visibility
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  const renderTable = (rows: WorkerDto[] | undefined) => {
    return (
      <>
        <SearchComponent onSearch={handleSearchResults} page={workerPageNumber} />
        <br/>
        <br/>
        {isLoading && <CircularProgress size={18} color="inherit" />}
        {!isLoading && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell align="right">First name</TableCell>
                    <TableCell align="right">Last name</TableCell>
                    <TableCell align="right">City</TableCell>
                    <TableCell align="right">SSN</TableCell>
                    <TableCell align="right">Phone number</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Disable worker</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row: WorkerDto) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.firstName}</TableCell>
                      <TableCell align="right">{row.lastName}</TableCell>
                      <TableCell align="right">{row.city}</TableCell>
                      <TableCell align="right">{row.ssn}</TableCell>
                      <TableCell align="right">{row.phoneNumber}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">
                        {row.active === "True" && (
                          <button
                            className="disable-btn"
                            onClick={() => {
                              handleEnableDisableWorker(row);
                            }}
                          >
                            Disable
                          </button>
                        )}
                        {row.active === "False" && (
                          <button
                            className="enable-btn"
                            onClick={() => {
                              handleEnableDisableWorker(row);
                            }}
                          >
                            Enable
                          </button>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <span
                          onClick={() => editRow(row)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Edit
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            { /** Not sure this button makes sense with the search function, hiding it for now **/}
            { /** @todo remove button if no one complains :-) **/}
            {showMoreVisible && (
                <Grid container justifyContent="center">
                  <Box mt={2}>
                    <Button variant="contained" onClick={() => loadMoreWorkers()}>
                      Show more
                    </Button>
                  </Box>
                </Grid>
            )}
          </>
        )}
      </>
    );
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let modalDataCopy = { ...modalData };
    switch (event.target.id) {
      case "worker-firstname":
        modalDataCopy.firstName = event.target.value;
        break;
      case "worker-lastname":
        modalDataCopy.lastName = event.target.value;
        break;
      case "worker-ssn":
        modalDataCopy.ssn = event.target.value;
        break;
      case "worker-address":
        modalDataCopy.address = event.target.value;
        break;
      case "worker-city":
        modalDataCopy.city = event.target.value;
        break;
      case "worker-postal-code":
        modalDataCopy.postalCode = event.target.value;
        break;
      case "worker-phone":
        modalDataCopy.phoneNumber = event.target.value;
        break;
      case "worker-email":
        modalDataCopy.email = event.target.value;
        break;
      case "worker-description":
        modalDataCopy.description = event.target.value;
        break;
      case "endorsement-company-id":
        parseInt(event.target.value)
          ? setEndorsementCompanyId(parseInt(event.target.value))
          : setEndorsementCompanyId("");
        break;
      default:
        break;
    }
    setModalData(modalDataCopy);
  };
  const resetCreationDeleteWorkerEndorsementModal = () => {
    setWorkerEndorsementModalOpen(false);
    setWorkerEndorsementRequestFailed(false);
    setWorkerEndorsementRequestSucceed(false);
    setEndorsementCompanyId("");
  };
  const renderModalData = (edit: boolean) => {
    return (
      <>
        <Grid
          style={{
            padding: "5%",
          }}
          container
          rowSpacing={3}
        >
          <Grid
            item
            xs={12}
            md={3}
            style={{
              padding: "2%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={deleteImageActive || !imageUrl ? person : imageUrl}
              className={imageStyles}
            />
            {imageUrl && (
              <Button
                onClick={() => {
                  setDeleteProfilePicModalOpen(true);
                }}
                className={deleteButton}
              >
                Delete profile picture
              </Button>
            )}
            <VideoContainer>
              <LabelStyled>Worker video presentation</LabelStyled>
              {workerVideo && !deleteVideoActive ? (
                <VideoWrapper>
                  <VideoPresentation controls src={workerVideo} />
                </VideoWrapper>
              ) : (
                <Text color={Color.SeaBlue400}>
                  Worker does not have a video presentation.
                </Text>
              )}
            </VideoContainer>
            {workerVideo && !deleteVideoActive && (
              <Button
                onClick={() => {
                  setDeleteVideoModalOpen(true);
                }}
                className={deleteButton}
              >
                Delete profile video
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <p className="hiddenContainer">hidden</p>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-firstname"
                  label="First name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.firstName || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-lastname"
                  label="Last name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.lastName || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-ssn"
                  label="SSN"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={modalData?.ssn || ""}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-address"
                  label="Address"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.address || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-postal-code"
                  label="Zip code"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.postalCode || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-city"
                  label="City"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.city || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-email"
                  label="Email"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.email || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-phone"
                  label="Phone number"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.phoneNumber || ""}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  id="worker-location"
                  label="GPS"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.location || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="worker-description"
                  label="Description"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={modalData?.description || ""}
                  multiline
                  maxRows={10}
                />
              </Grid>

              <Grid
                container
                spacing={1}
                style={{
                  paddingLeft: "32px",
                }}
              >
                <Text
                  style={{
                    fontFamily: FontFamily.MontserratSemiBold,
                  }}
                >
                  Skills
                </Text>
                {modalData?.workerSkills &&
                  modalData?.workerSkills.map((skl) => (
                    <>
                      <Grid item>
                        <Chip
                          onClick={() => {
                            setWorkerEndorsementModalOpen(true);
                            setCreateWorkerEndorsement(true);
                            setWorkerEndorsement(skl.skill);
                          }}
                          label={
                            skl.skill +
                            " " +
                            "|" +
                            " " +
                            skl.experience +
                            " Years"
                          }
                        ></Chip>
                      </Grid>
                    </>
                  ))}
                {modalData?.workerSkills?.length === 0 && (
                  <Text style={{ paddingLeft: "32px" }}>
                    Worker does not have any skills
                  </Text>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Text
                  style={{
                    paddingLeft: "32px",
                    paddingTop: "32px",
                    fontFamily: FontFamily.MontserratSemiBold,
                  }}
                >
                  Endorsements
                </Text>
                {workerEndorsements.length > 0 &&
                  workerEndorsements.map((end) => (
                    <div style={{ paddingLeft: "32px", paddingTop: "38px" }}>
                      <Grid item>
                        <Chip
                          label={end.skill}
                          onDelete={() => {
                            setWorkerEndorsement(end.skill);
                            setWorkerEndorsementModalOpen(true);
                            setCreateWorkerEndorsement(false);
                          }}
                        />
                      </Grid>
                    </div>
                  ))}
                {workerEndorsements.length === 0 && (
                  <Text style={{ paddingLeft: "32px", paddingTop: "32px" }}>
                    Worker does not have endorsements
                  </Text>
                )}
              </Grid>
              <Grid
                item
                xs={8}
                className={StaffingContainer}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  Staffing pools:{" "}
                </Text>
                <div className={PoolsContainer}>
                  {modalData.staffingPools &&
                    modalData.staffingPools.length > 0 &&
                    modalData.staffingPools.map((pool) => (
                      <Text
                        onClick={() => {
                          setShowRemoveModal(true);
                          setCurrentPool(pool);
                        }}
                        backgroundColor={Color.SeaBlue400}
                        padding
                        className={TagStyles}
                      >
                        {pool.name} X
                      </Text>
                    ))}
                  {modalData.staffingPools &&
                    modalData.staffingPools.length === 0 && (
                      <Text
                        color={Color.SeaBlue600}
                        style={{ marginLeft: "16px" }}
                      >
                        This worker is not in any staffing pools
                      </Text>
                    )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Add to staffing pool
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue="None selected"
                    value={currentPool?.name}
                    label="Add worker too staffing pool"
                    onChange={(e) => setAddToPoolModalOpen(true)}
                    style={{ width: "100%" }}
                  >
                    {staffingPools.map((pool) => (
                      <MenuItem
                        style={{ width: "100%" }}
                        value={pool.id}
                        onClick={() => setCurrentPool(pool)}
                      >
                        {pool.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                style={{ marginTop: "10px", paddingLeft: "16px" }}
                container
                spacing={2}
              >
                <Grid item xs={12} style={{ marginBottom: "-30px" }}>
                  <Text style={{ fontFamily: FontFamily.MontserratSemiBold }}>
                    Gigapay information
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Text>
                    Gigapay Employee Id:{" "}
                    {modalData.gigapayEmployeeId
                      ? modalData.gigapayEmployeeId
                      : "Not connected to Gigapay"}
                  </Text>
                </Grid>
              </Grid>
              {modalData.companyEmployee &&
                modalData.companyEmployee?.length > 0 && (
                  <Grid item xs={12}>
                    <span className={refCodeHeader}>Reference codes</span>
                    <div>
                      {modalData.companyEmployee?.map((ce) => (
                        <p className={refCodes} key={ce.id}>
                          {ce.referenceCode} - {ce.companyName}
                        </p>
                      ))}
                    </div>
                  </Grid>
                )}
              {edit && (
                <div className={buttonContainer}>
                  <ButtonStyled
                    disabled={isSaveLoading}
                    style={{ margin: 0 }}
                    onClick={updateWorker}
                  >
                    {isSaveLoading ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </ButtonStyled>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const handleDeleteWorkerEndorsementChange = (event: SelectChangeEvent) => {
    setWorkerEndorsementBy(event.target.value);
  };
  return (
    <LoggedInPageContainer>
      <H3>Workers</H3>
      {renderTable(workers)}
      <Dialog
        maxWidth="xl"
        style={{ marginLeft: "auto" }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        {renderModalData(isEditing)}
      </Dialog>

      <Dialog
        open={disableEnableModalOpen}
        onClose={() => setDisableEnableModalOpen(false)}
      >
        <DialogTitle>
          Are you sure you want to{" "}
          {modalData?.active === "True" ? "Disable" : "Enable"} the worker?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              disableEnableWorker(
                modalData?.id,
                modalData?.active === "True" ? false : true
              );
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setDisableEnableModalOpen(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteProfilePicModalOpen}
        onClose={() => setDeleteProfilePicModalOpen(false)}
      >
        <DialogTitle>
          Are you sure you want to delete the worker profile image?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteImageActive(true);
              deleteImage(modalData?.firebaseId);
              setDeleteProfilePicModalOpen(false);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteProfilePicModalOpen(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteVideoModalOpen}
        onClose={() => setDeleteVideoModalOpen(false)}
      >
        <DialogTitle>
          Are you sure you want to delete the workers video presentation?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteVideoActive(true);
              deleteVideo(modalData?.firebaseId);
              setDeleteVideoModalOpen(false);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteVideoModalOpen(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={workerEndorsementModalOpen}
        onClose={resetCreationDeleteWorkerEndorsementModal}
      >
        <DialogTitle>
          {createWorkerEndorsement ? "Create" : "Delete"} Endorsement
        </DialogTitle>
        <DialogContent>
          {createWorkerEndorsement && (
            <>
              <Grid item md={12} xs={12}>
                <TextField
                  id="endorsement-company-id"
                  label="CompanyId"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTextFieldChange}
                  value={endorsementCompanyId}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </Grid>
              <br />
              <Grid item md={12} xs={12}>
                <TextField
                  id="worker-endorsement"
                  label="Endorsement"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={workerEndorsement}
                  disabled={true}
                />
              </Grid>
            </>
          )}
          {!createWorkerEndorsement && (
            <>
              <InputLabel id="endorse-by-company-label">
                Endorsed by company
              </InputLabel>

              <Select
                labelId="endorse-by-company-label"
                id="endorse-by-company"
                value={workerEndorsementBy}
                label="Endorsed by company"
                onChange={handleDeleteWorkerEndorsementChange}
              >
                {workerEndorsements.map((e) => {
                  if (e.skill === workerEndorsement) {
                    return e.endorsedBy.map((c) => (
                      <MenuItem value={c.id}>{c.name}</MenuItem>
                    ));
                  }
                })}
              </Select>
            </>
          )}
          {workerEndorsementRequestSucceed && createWorkerEndorsement && (
            <Text color={Color.Success}>Endorsement successfully created.</Text>
          )}
          {workerEndorsementRequestFailed && createWorkerEndorsement && (
            <Text color={Color.Error}>Endorsement already created.</Text>
          )}

          {workerEndorsementRequestSucceed && !createWorkerEndorsement && (
            <Text color={Color.Success}>Endorsement successfully deleted.</Text>
          )}
          {workerEndorsementRequestFailed && !createWorkerEndorsement && (
            <Text color={Color.Error}>Endorsement could not be deleted.</Text>
          )}
        </DialogContent>
        <DialogActions>
          {createWorkerEndorsement && !workerEndorsementRequestSucceed && (
            <Button
              onClick={() => {
                handleCreateWorkerEndorsement(
                  modalData?.id,
                  workerEndorsement,
                  endorsementCompanyId,
                  endorsementGigId
                );
              }}
            >
              Create
            </Button>
          )}
          {!createWorkerEndorsement && !workerEndorsementRequestSucceed && (
            <Button
              onClick={() => {
                handleDeleteWorkerEndorsement(workerEndorsementBy);
              }}
            >
              Delete
            </Button>
          )}
          <Button onClick={resetCreationDeleteWorkerEndorsementModal} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showRemoveModal} onClose={() => setShowRemoveModal(false)}>
        {staffingIsLoading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to remove worker from{" "}
              {currentPool?.name || ""}?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  removeFromPool();
                }}
              >
                Yes
              </Button>
              <Button onClick={() => setShowRemoveModal(false)} autoFocus>
                No
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={addToPoolModalOpen}
        onClose={() => setAddToPoolModalOpen(false)}
      >
        {staffingIsLoading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to add {modalData.firstName} to this pool?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  addWorkerToPool();
                }}
              >
                Yes
              </Button>
              <Button onClick={() => setAddToPoolModalOpen(false)} autoFocus>
                No
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </LoggedInPageContainer>
  );
};

const imageStyles = css`
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;

const deleteButton = css`
  color: ${Color.BurntSienna};
  font-size: 0.75em;
  cursor: pointer;
  margin: 0.8em 0;
  text-align: center;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

const buttonContainer = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const refCodeHeader = css`
  font-family: ${FontFamily.MontserratSemiBold};
`;
const refCodes = css`
  font-size: 0.75rem;
`;

const TagStyles = css`
  margin: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const StaffingContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PoolsContainer = css`
  display: flex;
  flex-direction: row;
`;
