import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { Api } from "services/api/api.service";
import { useAuthStore } from "stores/authStore/authStore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Color } from "config/colors";
import { Box } from "@mui/system";
import { CompanyDto, CompanyRowData, CompanySettingDto, FeeData } from "model/Company";
import { FontFamily } from "config/font";
import { FormControlLabel, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { H3, Text } from "components/Typography/text.styled";
import { deleteCompanyImg, getCompanyImg, updateCompanyImg, } from "services/firebase/firebase.service";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import person from "images/ic_person_grey.svg";
import { CompaniesEditUsers } from "../../components/company-users/company-users.component";

export const CompaniesPage: React.FC = () => {
    const [ isFeatureEnabled, setIsFeatureEnabled ] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [ authState, authActions ] = useAuthStore();
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ disableEnableModalOpen, setDisableEnableModalOpen ] = useState(false);
    const [ editCompanyModalOpen, setEditCompanyModalOpen ] = useState(false);
    const [ deleteImageCompanyModalOpen, setDeleteImageCompanyModalOpen ] =
        useState(false);
    const [ isEditing, setIsEditing ] = useState(false);
    const [ editCompany, setEditCompany ] = useState(false);
    const [ modalData, setModalData ] = useState<CompanyDto | undefined>(undefined);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaveLoading, setSaveLoading ] = useState(false);
    const [ verifyLoading, setVerifyLoading ] = useState(false);
    const [ unverifiedCompaniesPageNumber, setUnverifiedCompaniesPageNumber ] =
        useState(0);
    const [ verifiedCompaniesPageNumber, setVerifiedCompaniesPageNumber ] =
        useState(0);
    const [ disabledCompaniesPageNumber, setDisabledCompaniesPageNumber ] =
        useState(0);
    const [ tabValue, setTabValue ] = useState(0);
    const [ imageUrl, setImageUrl ] = useState<string | null>(null);
    const [ verifiedCompanies, setVerifiedCompanies ] = useState<CompanyDto[]>([]);
    const [ verifiedRows, setVerifiedRows ] = useState<CompanyRowData[]>([]);
    const [ unVerifiedCompanies, setUnVerifiedCompanies ] = useState<CompanyDto[]>(
        []
    );

    const [ disabledCompanies, setDisabledCompanies ] = useState<CompanyDto[]>([]);
    const [ unVerifiedRows, setUnVerifiedRows ] = useState<CompanyRowData[]>([]);
    const [ disabledRows, setDisabledRows ] = useState<CompanyRowData[]>([]);
    const [ simflexityPaySent, setSimflexityPaySent ] = useState(false);
    const [ companyType, setcompanyType ] = useState("");
    const [ showRemoveModal, setShowRemoveModal ] = useState(false);
    const [ currentPool, setCurrentPool ] = useState<{
        name: string;
        id: number;
    } | null>(null);
    const [ staffingIsLoading, setStaffingIsLoading ] = useState(false);
    const [ staffingPools, setStaffingPools ] = useState<
        { id: number; name: string }[]
    >([]);
    const [ addToPoolModalOpen, setAddToPoolModalOpen ] = useState(false);

    const [ feeTypes, setFeeTypes ] = useState<{ id: string; name: string }[]>([
        { id: "Flat", name: 'Flat' },
        { id: "Percent", name: 'Percent % (example 1,25)' },
    ]);

    const [ companyProductTypes, setCompanyProductTypes ] = useState<{ id: string; name: string }[]>([
        { id: "Basic", name: 'Basic' },
        { id: "Standard Flex", name: 'Standard Flex' },
        { id: "Standard Total", name: 'Standard Total' },
        { id: "Premium Flex", name: 'Premium Flex' },
        { id: "Premium Total", name: 'Premium Total' },
    ]);

    useEffect(() => {
        if (authState.signedIn) {
            getUnVerifiedCompanies();
        }
    }, [ authState ]);

    const reloadData = () => {
        switch (tabValue) {
            case 0:
                getUnVerifiedCompanies();
                break;
            case 1:
                getVerifiedCompanies();
                break;
            case 2:
                getDisabledCompanies();
                break;
            default:
                break;
        }
    };

    const handleStampClockToggleChange = (companyId: number | undefined) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        try {
            const result = Api().admin.stampClockFeatureToggle(companyId, value);
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsFeatureEnabled(value);
        }
    };

    const handleCompaniesTabChange = (event: any, newValue: number) => {
        if (newValue === 0) {
            getUnVerifiedCompanies();
        } else if (newValue === 1) {
            getVerifiedCompanies();
        } else if (newValue === 2) {
            getDisabledCompanies();
        }
        setTabValue(newValue);
    };

    const getVerifiedCompanies = () => {
        setIsLoading(true);
        setEditCompany(false);
        Api()
            .admin.getCompanies("verified", 0) // We set 0 to fetch iniital data when tab loads
            .then((res) => {
                setVerifiedCompanies(res.data);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setVerifiedRows(rows);
                setIsLoading(false);
            });
    };

    const getCompanyImage = (companyId: number | undefined) => {
        if (companyId) {
            getCompanyImg(companyId)
                .then((url) => {
                    setImageUrl(url);
                })
                .catch(() => {
                });
        }
    };
    const getUnVerifiedCompanies = () => {
        setIsLoading(true);
        Api()
            .admin.getCompanies("unverified", 0) // We set 0 to fetch iniital data when tab loads
            .then((res) => {
                setUnVerifiedCompanies(res.data);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setUnVerifiedRows(rows);
                setIsLoading(false);
            });
    };

    const getDisabledCompanies = () => {
        setIsLoading(true);
        setEditCompany(false);
        Api()
            .admin.getCompanies("disabled", 0) // We set 0 to fetch iniital data when tab loads
            .then((res) => {
                setDisabledCompanies(res.data);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setDisabledRows(rows);
                setIsLoading(false);
            });
    };

    const buildCompanySettings = (data: FeeData): CompanySettingDto[] => {
        const companySettingsTemplate: CompanySettingDto[] = [];

        data.fees.forEach((fee, index) => {
            companySettingsTemplate.push(
                {
                    companyId: data.companyId,
                    key: fee.key,
                    value: fee.value.toString(),
                },
                {
                    companyId: data.companyId,
                    key: `${fee.key}Type`,
                    value: fee.valueType,
                }
            )
        });

        return companySettingsTemplate;
    }

    const checkCompanyFeeKeys = (settings: CompanySettingDto[]): boolean => {
        const requiredKeys = [ "MarketplaceFee", "WorkpoolFee", "StaffingFee" ];
        const keysInSettings = settings.map(setting => setting.key);
        return requiredKeys.every(key => keysInSettings.includes(key));
    }

    const createData = (company: CompanyDto): CompanyRowData => {
        if (!checkCompanyFeeKeys(company?.companySettings || [])) {
            // get company settings from backend, if no company key data exists in db a default value will be returned
            Api().admin.getCompanySetting(company?.id)
                .then((result) => {
                    company.companySettings = [ ...company.companySettings || [], ...buildCompanySettings(result) ];
                });
        }

        return {
            id: company.id,
            companyName: company.companyName,
            legalName: company.legalName,
            referenceCode: company.referenceCode,
            registrationNumber: company.registrationNumber,
            email: company.email,
            contact: company.contactPerson,
            active: company.active,
            companySettings: company.companySettings,
        };
    };

    const loadMoreUnverifiedCompanies = () => {
        setUnverifiedCompaniesPageNumber(unverifiedCompaniesPageNumber + 1);
        Api()
            .admin.getCompanies("unverified", unverifiedCompaniesPageNumber + 1)
            .then((res) => {
                setUnVerifiedCompanies([ ...unVerifiedCompanies, ...res.data ]);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setUnVerifiedRows([ ...unVerifiedRows, ...rows ]);
            });
    };

    const loadMoreVerifiedCompanies = () => {
        setVerifiedCompaniesPageNumber(verifiedCompaniesPageNumber + 1);
        Api()
            .admin.getCompanies("verified", verifiedCompaniesPageNumber + 1)
            .then((res) => {
                setVerifiedCompanies([ ...verifiedCompanies, ...res.data ]);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setVerifiedRows([ ...verifiedRows, ...rows ]);
            });
    };

    const loadMoreDisabledCompanies = () => {
        setDisabledCompaniesPageNumber(disabledCompaniesPageNumber + 1);
        Api()
            .admin.getCompanies("disabled", disabledCompaniesPageNumber + 1)
            .then((res) => {
                setDisabledCompanies([ ...disabledCompanies, ...res.data ]);
                const rows = res.data.map((company: CompanyDto) => createData(company));
                setDisabledRows([ ...disabledRows, ...rows ]);
            });
    };

    const handleRowClick = (
        row: CompanyRowData,
        edit: boolean,
        disabled: boolean,
        companyType: string,
        disableEnableCompany?: boolean
    ) => {
        setcompanyType(companyType);
        if (companyType === "disabled") {
            const companyModalData = disabledCompanies.find(
                (company) => company.id === row.id
            );
            setModalData(companyModalData);
            getCompanyImage(companyModalData?.id);
        } else if (companyType === "unVerified") {
            const companyModalData = unVerifiedCompanies.find(
                (company) => company.id === row.id
            );
            setModalData(companyModalData);
            getCompanyImage(companyModalData?.id);
        } else if (companyType === "verified") {
            const companyModalData = verifiedCompanies.find(
                (company) => company.id === row.id
            );
            setModalData(companyModalData);
            getCompanyImage(companyModalData?.id);
        }
        setIsEditing(edit);
        setEditCompany(false);
        if (disableEnableCompany) {
            setDisableEnableModalOpen(true);
        } else {
            setModalOpen(true);
        }
    };

    const removeFromPool = () => {
        if (currentPool && modalData?.id) {
            setStaffingIsLoading(true);
            Api()
                .admin.deleteCompanyFromStaffingPool(currentPool?.id, modalData.id)
                .then(() => {
                    setShowRemoveModal(false);
                    setModalData({
                        ...modalData,
                        staffingPools:
                            modalData?.staffingPools &&
                            modalData?.staffingPools.filter(
                                (pool) => pool.id !== currentPool.id
                            ),
                    });
                    setStaffingIsLoading(false);
                })
                .catch((err) => {
                    console.log("ERROR REMOVING COMPANY FROM STAFFING POOL", err);
                    setStaffingIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (modalData) {
            Api()
                .admin.getStaffingPools()
                .then((res) => {
                    setStaffingPools(res);
                });
        }
    }, [ modalData ]);

    const addCompanyToPool = () => {
        if (currentPool && modalData?.id) {
            setStaffingIsLoading(true);
            Api()
                .admin.addCompanyToStaffingPool(modalData.id, currentPool.id)
                .then((res) => {
                    modalData.staffingPools &&
                    setModalData({
                        ...modalData,
                        staffingPools: [ ...modalData.staffingPools, currentPool ],
                    });
                    setStaffingIsLoading(false);
                    setCurrentPool(null);
                    setAddToPoolModalOpen(false);
                })
                .catch((err) => {
                    console.log("ERROR ADDING WORKER TO STAFFING POOL", err);
                    setStaffingIsLoading(false);
                });
        }
    };

    const [ visible, setVisible ] = useState(true);

    const renderTable = (
        rows: CompanyRowData[],
        editRow: boolean = false,
        disabled: boolean,
        companyType: string
    ) => {
        return (
            <>
                {isLoading && <CircularProgress size={18} color="inherit"/>}
                {!isLoading && (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Company Name</TableCell>
                                        <TableCell align="right">Reference code</TableCell>
                                        <TableCell align="right">Registration number</TableCell>
                                        <TableCell align="right">Email</TableCell>
                                        <TableCell align="right">Contact person</TableCell>
                                        <TableCell align="right">Disable company</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row: CompanyRowData) => (
                                        <TableRow
                                            key={row.registrationNumber}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="right">{row.companyName}</TableCell>
                                            <TableCell align="right">{row.referenceCode}</TableCell>
                                            <TableCell align="right">
                                                {row.registrationNumber}
                                            </TableCell>
                                            <TableCell align="right">{row.email}</TableCell>
                                            <TableCell align="right">{row.contact}</TableCell>
                                            <TableCell align="right">
                                                {row.active && (
                                                    <button
                                                        disabled={verifyLoading}
                                                        color="error"
                                                        onClick={() =>
                                                            handleRowClick(
                                                                row,
                                                                editRow,
                                                                disabled,
                                                                companyType,
                                                                true
                                                            )
                                                        }
                                                        className="disable-btn"
                                                    >
                                                        Disable
                                                    </button>
                                                )}
                                                {!row.active && (
                                                    <button
                                                        disabled={verifyLoading}
                                                        color="success"
                                                        onClick={() =>
                                                            handleRowClick(
                                                                row,
                                                                editRow,
                                                                disabled,
                                                                companyType,
                                                                true
                                                            )
                                                        }
                                                        className="enable-btn"
                                                    >
                                                        Enable
                                                    </button>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                            <span
                                                onClick={() =>
                                                    handleRowClick(row, editRow, disabled, companyType)
                                                }
                                                style={{ color: "blue", cursor: "pointer" }}
                                            >
                                              Edit
                                            </span>
                                            </TableCell>
                                            <TableCell align="right">
                                                <CompaniesEditUsers data={row} onClose={reloadData}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {tabValue === 0 && (
                            <Grid container justifyContent="center">
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => loadMoreUnverifiedCompanies()}
                                    >
                                        Show more
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                        {tabValue === 1 && (
                            <Grid container justifyContent="center">
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => loadMoreVerifiedCompanies()}
                                    >
                                        Show more
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                        {tabValue === 2 && (
                            <Grid container justifyContent="center">
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => loadMoreDisabledCompanies()}
                                    >
                                        Show more
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </>
                )}
            </>
        );
    };

    const handleTextFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        let modalDataCopy = { ...modalData };

        switch (event.target.id) {
            case "company-name":
                modalDataCopy.companyName = event.target.value;
                break;
            case "company-legal-name":
                modalDataCopy.legalName = event.target.value;
                break;
            case "company-contact":
                modalDataCopy.contactPerson = event.target.value;
                break;
            case "company-phone":
                modalDataCopy.phoneNumber = event.target.value;
                break;
            case "company-email":
                modalDataCopy.email = event.target.value;
                break;
            case "company-address":
                modalDataCopy.address = event.target.value;
                break;
            case "company-postal":
                modalDataCopy.postalCode = event.target.value;
                break;
            case "company-city":
                modalDataCopy.city = event.target.value;
                break;
            case "company-website":
                modalDataCopy.websiteUrl = event.target.value;
                break;
            case "company-description":
                modalDataCopy.description = event.target.value;
                break;
            case "company-registration":
                modalDataCopy.registrationNumber = event.target.value;
                break;
            case "company-collective-agreement":
                modalDataCopy.collectiveAgreement = event.target.value;
                break;
            case "market-fee-value":
                modalDataCopy = updateCompanySettings(modalDataCopy, 'MarketplaceFee', event.target.value);
                break;
            case "workpool-fee-value":
                modalDataCopy = updateCompanySettings(modalDataCopy, 'WorkpoolFee', event.target.value);
                break;
            case "staffing-fee-value":
                modalDataCopy = updateCompanySettings(modalDataCopy, 'StaffingFee', event.target.value);
                break;
            default:
                break;
        }
        setModalData(modalDataCopy);
    };

    const updateCompanySettings = (company: any, key: any, newValue: any) => {
        let updatedCompany = { ...company };

        for (let setting of updatedCompany.companySettings) {
            if (setting.key === key) {
                setting.value = newValue;
                break;
            }
        }

        return updatedCompany;
    }

    const sendSimflexityPayInfo = () => {
        setSaveLoading(true);
        Api()
            .admin.enableSimflexityPayCompany(modalData?.id)
            .then(() => {
                getVerifiedCompanies();
                setSimflexityPaySent(true);
                setSaveLoading(false);
            });
    };
    const updateSaveCompanyData = (
        companies: CompanyDto[],
        companyRows: CompanyRowData[],
        companyType: string
    ) => {
        const tempCompanyRows = companyRows.map((company) => {
            if (company.id === modalData?.id) {
                const tempCompany = {
                    active: company?.active,
                    id: company?.id,
                    companyName: modalData?.companyName,
                    contact: modalData?.contactPerson,
                    email: modalData?.email,
                    referenceCode: modalData?.referenceCode,
                    registrationNumber: modalData?.registrationNumber,
                };
                company = modalData ? tempCompany : company;
            }
            return company;
        });
        const tempCompanies = companies.map((company) => {
            if (company.id === modalData?.id) {
                company = modalData ? modalData : company;
            }
            return company;
        });
        if (companyType === "unVerified") {
            setUnVerifiedRows(tempCompanyRows);
            setUnVerifiedCompanies(tempCompanies);
        } else if (companyType === "verified") {
            setVerifiedRows(tempCompanyRows);
            setVerifiedCompanies(tempCompanies);
        } else if (companyType === "disabled") {
            setDisabledRows(tempCompanyRows);
            setDisabledCompanies(tempCompanies);
        }
    };
    const saveCompany = (companyType: string) => {
        setSaveLoading(true);
        Api().admin.updateCompanyFees({ ...modalData });

        Api()
            .admin.updateCompany({
            ...modalData,
            billingEmail: modalData?.billingEmail
                ? modalData?.billingEmail
                : modalData?.email || "",
            billingAddress: modalData?.billingAddress
                ? modalData?.billingAddress
                : `${modalData?.address}, ${modalData?.postalCode}, ${modalData?.city}` ||
                "",
        })
            .then(() => {
                setSaveLoading(false);
                setModalOpen(false);
                setEditCompanyModalOpen(false);
                if (companyType === "unVerified") {
                    updateSaveCompanyData(
                        unVerifiedCompanies,
                        unVerifiedRows,
                        companyType
                    );
                } else if (companyType === "verified") {
                    updateSaveCompanyData(verifiedCompanies, verifiedRows, companyType);
                } else if (companyType === "disabled") {
                    updateSaveCompanyData(disabledCompanies, disabledRows, companyType);
                }
            })
            .catch((err) => console.log(err));
    };
    const disableEnableCompany = (id: number | undefined, status: boolean) => {
        setSaveLoading(true);
        Api()
            .admin.updateCompany({ active: status, id: id })
            .then(() => {
                setSaveLoading(false);
                setDisableEnableModalOpen(false);
                setModalOpen(false);
                if (tabValue === 0) {
                    getUnVerifiedCompanies();
                } else if (tabValue === 1) {
                    getVerifiedCompanies();
                } else if (tabValue === 2) {
                    getDisabledCompanies();
                }
            })
            .catch((err) => console.log(err));
    };
    const verifyCompany = () => {
        setVerifyLoading(true);
        Api()
            .admin.verifyCompany(modalData?.id)
            .then(() => {
                setVerifyLoading(false);
                setModalOpen(false);
                if (tabValue === 0) {
                    getUnVerifiedCompanies();
                } else {
                    getVerifiedCompanies();
                }
            });
    };
    const handleDeleteCompanyImageUpload = (id: number | undefined) => {
        if (id) {
            deleteCompanyImg(id).then(() => {
                setDeleteImageCompanyModalOpen(false);
                getCompanyImage(id);
            });
        }
    };

    const marketplaceFeeTypeSetting = modalData?.companySettings?.find(setting => setting.key === 'MarketplaceFeeType');
    const marketplaceFeeTypeValue = modalData?.companySettings?.find(setting => setting.key === 'MarketplaceFee');
    const [ marketplaceFeeType, setMarketplaceFeeType ] = useState(marketplaceFeeTypeSetting?.value);

    const workpoolFeeTypeSettings = modalData?.companySettings?.find(setting => setting.key === 'WorkpoolFeeType');
    const workpoolFeeTypeValue = modalData?.companySettings?.find(setting => setting.key === 'WorkpoolFee');
    const [ workpoolFeeType, setWorkPoolFeeType ] = useState(workpoolFeeTypeSettings?.value);

    const staffingFeeTypeSettings = modalData?.companySettings?.find(setting => setting.key === 'StaffingFeeType');
    const staffingFeeTypeValue = modalData?.companySettings?.find(setting => setting.key === 'StaffingFee');
    const [ staffingFeeType, setStaffingFeeType ] = useState(staffingFeeTypeSettings?.value);

    const [currentCompanyProductType, setCurrentCompanyProductType] = useState<string>('Basic');

    const getCompanySetting = (companyId: number | undefined, key: string): boolean => {
        const setting = modalData?.companySettings?.find(
            (setting) =>
                setting.companyId === companyId &&
                setting.key === key
        )?.value;

        return setting !== undefined ? setting.toLowerCase() === 'true' : false;
    };

    const handleModalClose = () => {
        setModalOpen(false);
        reloadData();
    };

    const handleDisableEnableModalClose = () => {
        setDisableEnableModalOpen(false);
        reloadData();
    };

    const handleEditCompanyModalClose = () => {
        setEditCompanyModalOpen(false);
        reloadData();
    };

    const handleDeleteImageCompanyModalClose = () => {
        setDeleteImageCompanyModalOpen(false);
        reloadData();
    };

    const handleRemoveModalClose = () => {
        setShowRemoveModal(false);
        reloadData();
    };

    const handleAddToPoolModalClose = () => {
        setAddToPoolModalOpen(false);
        reloadData();
    };


    const getCompanyProductType = async (companyId: number | undefined): Promise<string> => {
        try {
            const productType = await Api().admin.getCompanyProductType(companyId);
            return productType.membershipDisplayName;
        } catch (error: any) {
            console.error(error);
            return 'Unknown';
        }
    };

    const setCompanyProductType = async (companyId: number | undefined, productType: string): Promise<void> => {
        try {
            // Remove white spaces from product type
            productType = productType.replace(/\s/g, '');
            await Api().admin.setCompanyProductType(companyId, productType);
        } catch (error: any) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (modalOpen) {
            if (
                modalData &&
                modalData.companySettings &&
                // @ts-ignore
                modalData.companySettings.find(
                    (setting) =>
                        setting.companyId === modalData.id &&
                        setting.key === "EnableSimflexityPay"
                )?.value.toLowerCase() === "true"
            ) {
                setSimflexityPaySent(true);
            } else {
                setSimflexityPaySent(false);
            }
            setIsFeatureEnabled(getCompanySetting(modalData?.id, 'LocalStampClock'));

            getCompanyProductType(modalData?.id).then((productType) => {
                setCurrentCompanyProductType(productType || '');
            });
        }
    }, [ modalOpen, modalData ]);

    const renderModalData = (edit: boolean) => {
        const handleCompanyImageUpload = (
            event: React.ChangeEvent<HTMLInputElement>,
            id: number | undefined
        ) => {
            var file = event?.target?.files;
            if (file && id) {
                updateCompanyImg(id, file[0]).then(() => {
                    getCompanyImage(id);
                });
            }
        };

        const handleSelectMarketplaceFeeChange = (event: any) => {
            setMarketplaceFeeType(event.target.value);

            if (marketplaceFeeTypeSetting) {
                marketplaceFeeTypeSetting.value = event.target.value;
            }
        };

        const handleSelectWorkpoolFeeChange = (event: any) => {
            setWorkPoolFeeType(event.target.value);

            if (workpoolFeeTypeSettings) {
                workpoolFeeTypeSettings.value = event.target.value;
            }
        };

        const handleProductTypeChange = (companyId: number | undefined) => (event: any) => {
            const productType = event.target.value;
            setCurrentCompanyProductType(productType);
            setCompanyProductType(companyId, productType);
        };

        const handleSelectStaffingFeeChange = (event: any) => {
            setStaffingFeeType(event.target.value);

            if (staffingFeeTypeSettings) {
                staffingFeeTypeSettings.value = event.target.value;
            }
        };

        return (
            <>
                <Grid
                    style={{ padding: "2%" }}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12} md={3}>
                            <img
                                src={!imageUrl ? person : imageUrl}
                                className={imageStyles}
                            />
                            {imageUrl && (
                                <ButtonStyled
                                    onClick={() => setDeleteImageCompanyModalOpen(true)}
                                >
                                    Delete
                                </ButtonStyled>
                            )}
                            {!imageUrl && (
                                <input
                                    accept="image/*"
                                    style={{
                                        width: "100%",
                                        fontFamily: FontFamily.MontserratSemiBold,
                                    }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(e) => handleCompanyImageUpload(e, modalData?.id)}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <p className="hiddenContainer">hidden</p>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        margin="none"
                                        label="Company name"
                                        id="company-name"
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                            fontFamily: FontFamily.MontserratSemiBold,
                                        }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.companyName || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        margin="none"
                                        label="Legal name"
                                        id="company-legal-name"
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                            fontFamily: FontFamily.MontserratSemiBold,
                                        }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.legalName || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-contact"
                                        label="Contact"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.contactPerson || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-phone"
                                        label="Phone number"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.phoneNumber || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-email"
                                        label="Email"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.email || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-address"
                                        label="Address"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.address || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-postal"
                                        label="Postal code"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.postalCode || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-city"
                                        label="City"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.city || ""}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-basic"
                                        label="Country"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={modalData?.countryId === 1 ? "Sweden" : "Narnia"}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="company-reference"
                                        label="Reference Code"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={modalData?.referenceCode || ""}
                                        disabled={true}
                                    />
                                </Grid>

                                { /* Marketplace Fees */}
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="market-fee-type-select-label">
                                            Marketplace Fee Type
                                        </InputLabel>
                                        <Select
                                            labelId="market-fee-type-select-label"
                                            id="market-fee-type-select"
                                            onChange={handleSelectMarketplaceFeeChange}
                                            value={marketplaceFeeTypeSetting?.value}
                                            label="Add company too staffing pool"
                                            style={{ width: "100%" }}
                                            disabled={!editCompany}
                                        >
                                            {feeTypes.map(fee => <MenuItem key={fee.id}
                                                                           value={fee.id}>{fee.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="market-fee-value"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={marketplaceFeeTypeValue?.value}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    { /* Company Product */}
                                    <FormControl fullWidth>
                                        <InputLabel id="company-product-select-label">
                                            Type of Company Product
                                        </InputLabel>
                                        <Select
                                            labelId="company-product-select-label"
                                            id="company-product-select"
                                            value={currentCompanyProductType}
                                            onChange={handleProductTypeChange(modalData?.id)}
                                            style={{ width: "100%" }}
                                            disabled={!editCompany}
                                        >
                                            {companyProductTypes.map(product =>
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.name}
                                                </MenuItem>
                                            )}
                                        </Select>                                    </FormControl>
                                </Grid>
                                { /* Workpool Fees */}
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="workpool-fee-type-select-label">
                                            Workpool Fee Type
                                        </InputLabel>
                                        <Select
                                            labelId="workpool-fee-type-select-label"
                                            id="workpool-fee-type-select"
                                            onChange={handleSelectWorkpoolFeeChange}
                                            value={workpoolFeeTypeSettings?.value}
                                            style={{ width: "100%" }}
                                            disabled={!editCompany}
                                        >
                                            {feeTypes.map(fee => <MenuItem key={fee.id}
                                                                           value={fee.id}>{fee.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="workpool-fee-value"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={workpoolFeeTypeValue?.value}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    { /* Local Stamp Clock */ }
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isFeatureEnabled}
                                                onChange={handleStampClockToggleChange(modalData?.id)}
                                                name="featureToggle"
                                                color="primary"
                                            />
                                        }
                                        label="Only check-in by Stamp Clock"
                                        disabled={!editCompany}
                                    />
                                </Grid>

                                { /* Staffing Fees */}
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="staffing-fee-type-select-label">
                                            Staffing Fee Type
                                        </InputLabel>
                                        <Select
                                            labelId="staffing-fee-type-select-label"
                                            id="staffing-fee-type-select"
                                            onChange={handleSelectStaffingFeeChange}
                                            value={staffingFeeTypeSettings?.value}
                                            style={{ width: "100%" }}
                                            disabled={!editCompany}
                                        >
                                            {feeTypes.map(fee => <MenuItem key={fee.id}
                                                                           value={fee.id}>{fee.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="staffing-fee-value"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleTextFieldChange}
                                        value={staffingFeeTypeValue?.value}
                                        disabled={!editCompany}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            id="company-collective-agreement"
                            label="Collective agreement"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleTextFieldChange}
                            value={modalData?.collectiveAgreement || ""}
                            disabled={!editCompany}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="company-website"
                            label="Website"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleTextFieldChange}
                            value={modalData?.websiteUrl || ""}
                            disabled={!editCompany}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="company-registration"
                            label="Registration Number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleTextFieldChange}
                            value={modalData?.registrationNumber || ""}
                            disabled={!editCompany}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="company-description"
                            label="Description"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleTextFieldChange}
                            value={modalData?.description || ""}
                            multiline
                            disabled={!editCompany}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Text>Users</Text>
                        {modalData?.companyUsers?.length &&
                            modalData?.companyUsers.map((user) => {
                                return (
                                    <Grid container rowSpacing={3} spacing={3}>
                                        {user.email && (
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label="Email"
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    onChange={handleTextFieldChange}
                                                    value={user.email || ""}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Phone number"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                                onChange={handleTextFieldChange}
                                                value={user.phoneNumber || "None"}
                                                disabled={true}
                                            />
                                        </Grid>
                                        {user.role && (
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label="Role"
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    onChange={handleTextFieldChange}
                                                    value={user.role || ""}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid item xs={10} className={StaffingContainer}>
                        <Text>Staffing pools: </Text>
                        <div className={PoolsContainer}>
                            {modalData?.staffingPools &&
                                modalData?.staffingPools.map((pool) => (
                                    <Text
                                        onClick={() => {
                                            setShowRemoveModal(true);
                                            setCurrentPool(pool);
                                        }}
                                        backgroundColor={Color.SeaBlue400}
                                        padding
                                        className={TagStyles}
                                    >
                                        {pool.name} X
                                    </Text>
                                ))}
                            {modalData?.staffingPools &&
                                modalData?.staffingPools.length === 0 && (
                                    <Text color={Color.SeaBlue600} style={{ marginLeft: "16px" }}>
                                        This company does not have access to any staffing pools
                                    </Text>
                                )}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Add to staffing pool
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue="None selected"
                                value={currentPool?.name}
                                label="Add company too staffing pool"
                                onChange={(e) => setAddToPoolModalOpen(true)}
                                style={{ width: "100%" }}
                            >
                                {staffingPools.map((pool) => (
                                    <MenuItem
                                        style={{ width: "100%" }}
                                        value={pool.id}
                                        onClick={() => setCurrentPool(pool)}
                                    >
                                        {pool.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {edit && (
                        <Grid item xs={12} md={4}>
                            <div>
                                {!modalData?.verified && (
                                    <ButtonStyled
                                        disabled={verifyLoading}
                                        style={{ margin: 0, width: "100%", marginBottom: 10 }}
                                        onClick={verifyCompany}
                                    >
                                        {verifyLoading ? (
                                            <CircularProgress size={18} color="inherit"/>
                                        ) : (
                                            "Verify Company"
                                        )}
                                    </ButtonStyled>
                                )}

                                {editCompany && (
                                    <ButtonStyled
                                        disabled={isSaveLoading}
                                        style={{ margin: 0, width: "100%" }}
                                        onClick={() => setEditCompanyModalOpen(true)}
                                    >
                                        Save Company
                                    </ButtonStyled>
                                )}
                                {!editCompany && (
                                    <ButtonStyled
                                        disabled={isSaveLoading}
                                        style={{ margin: 0, width: "100%" }}
                                        onClick={() => setEditCompany(true)}
                                    >
                                        {isSaveLoading ? (
                                            <CircularProgress size={18} color="inherit"/>
                                        ) : (
                                            "Edit Company"
                                        )}
                                    </ButtonStyled>
                                )}
                            </div>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                            display: "flex",
                            flex: 1,
                            // flexDirection: "column",
                            justifyContent: "flex-end",
                        }}
                    >
                        {!simflexityPaySent && (
                            <div>
                                <ButtonStyled
                                    disabled={isSaveLoading}
                                    style={{ margin: 0, width: "100%", marginBottom: 10 }}
                                    onClick={sendSimflexityPayInfo}
                                >
                                    {isSaveLoading ? (
                                        <CircularProgress size={18} color="inherit"/>
                                    ) : (
                                        "Send SimflexityPay permission"
                                    )}
                                </ButtonStyled>
                                {/* <p
                style={{
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Email will be sent. Make sure email is valid
              </p> */}
                            </div>
                        )}
                        {simflexityPaySent && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Text>
                                    SimflexityPay permission has been sent, company may now accept
                                    agreement in WAPP / APP
                                </Text>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <LoggedInPageContainer>
            <H3>Companies</H3>
            <Tabs value={tabValue} onChange={handleCompaniesTabChange} centered>
                <Tab label="Not verfied"/>
                <Tab label="Verified"/>
                <Tab label="Disabled companies"/>
            </Tabs>

            {tabValue === 0 && renderTable(unVerifiedRows, true, false, "unVerified")}
            {tabValue === 1 && renderTable(verifiedRows, true, false, "verified")}
            {tabValue === 2 && renderTable(disabledRows, true, true, "disabled")}

            <Dialog
                maxWidth="xl"
                style={{ marginLeft: "auto" }}
                open={modalOpen}
                onClose={handleModalClose}
            >
                {renderModalData(isEditing)}
            </Dialog>
            <Dialog
                fullScreen={fullScreen}
                open={disableEnableModalOpen}
                onClose={handleDisableEnableModalClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to {modalData?.active ? "Disable" : "Enable"}{" "}
                    the company?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() =>
                            modalData?.active
                                ? disableEnableCompany(modalData?.id, false)
                                : disableEnableCompany(modalData?.id, true)
                        }
                    >
                        Yes
                    </Button>
                    <Button onClick={() => setDisableEnableModalOpen(false)} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={editCompanyModalOpen}
                onClose={handleEditCompanyModalClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to update the company?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            saveCompany(companyType);
                        }}
                    >
                        Yes
                    </Button>
                    <Button onClick={handleEditCompanyModalClose} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteImageCompanyModalOpen}
                onClose={handleDeleteImageCompanyModalClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete the company Image?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleDeleteCompanyImageUpload(modalData?.id);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleDeleteImageCompanyModalClose}
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showRemoveModal} onClose={handleRemoveModalClose}>
                {staffingIsLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to remove access to{" "}
                            {currentPool?.name || ""}?
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    removeFromPool();
                                }}
                            >
                                Yes
                            </Button>
                            <Button onClick={handleRemoveModalClose} autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                open={addToPoolModalOpen}
                onClose={handleAddToPoolModalClose}
            >
                {staffingIsLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to add {modalData?.companyName} to this
                            pool?
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    addCompanyToPool();
                                }}
                            >
                                Yes
                            </Button>
                            <Button onClick={handleAddToPoolModalClose} autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </LoggedInPageContainer>
    );
};

const imageStyles = css`
    width: 100%;
    height: 90%;
    max-height: 250px;
    padding: 5%;
    object-fit: contain;
    box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;
const TagStyles = css`
  margin: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const StaffingContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const PoolsContainer = css`
  display: flex;
  flex-direction: row;
`;
