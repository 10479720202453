import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from "@material-ui/core";
import { formatDateString } from "utils/utils";
import { TDS, lineBreak, getApprovedBy } from "./styles";

interface ConfirmDialogProps {
    confirmOpen: boolean;
    setConfirmOpen: (open: boolean) => void;
    selectedRow: any;
    handleConfirmAction: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
        confirmOpen,
        setConfirmOpen,
        selectedRow,
        handleConfirmAction
    }) => {
    return (
        <Dialog
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">{"Confirm Reset Deposit"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    <b>Are you sure you want to reset the deposit for this time report?</b>
                    <div className={lineBreak}>
                        <span className={TDS}>
                            <strong>Time Report ID:</strong> {selectedRow?.timeReportId}
                        </span>
                        <span className={TDS}>
                            <strong>Company Name (ID):</strong> {selectedRow?.companyName} ({selectedRow?.companyId})
                        </span>
                        <span className={TDS}>
                            <strong>Worker Name (ID):</strong> {selectedRow?.workerFirstName} {selectedRow?.workerLastName} ({selectedRow?.workerId})
                        </span>
                        <span className={TDS}>
                            <strong>Start Time:</strong> {formatDateString(selectedRow?.startTime)}
                        </span>
                        <span className={TDS}>
                            <strong>End Time:</strong> {formatDateString(selectedRow?.endTime)}
                        </span>
                        <span className={TDS}>
                            <strong>Status:</strong> {selectedRow?.status}
                        </span>
                        <span className={TDS}>
                            <strong>Resolution:</strong> {selectedRow?.resolution}
                        </span>
                        <span className={TDS}>
                            <strong>Approved By (ID):</strong> {getApprovedBy(selectedRow)}
                        </span>
                        <span className={TDS}>
                            <strong>Updated At:</strong> {formatDateString(selectedRow?.updatedAt)}
                        </span>
                    </div>
                    <hr/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmAction} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};