import React, { useEffect, useState } from 'react';
import { Api } from "services/api/api.service";
import { TimeReportRow } from "interfaces/admin";
import { CircularProgress } from "@material-ui/core";
import { H3 } from "components/Typography/text.styled";
import { ErrorDialog } from "./components/ErrorDialog";
import { ConfirmDialog } from "./components/ConfirmDialog";
import { RenderTimeReportTable } from "./components/TimeReportTable";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";

const handleCancelTimeReport = async (
    row: any,
    setError: (error: string) => void,
    setErrorOpen: (open: boolean) => void
) => {
    try {
        await Api().admin.cancelTimeReport(row?.timeReportId, row?.companyId);
    } catch (error: any) {
        setError(error.message);
        setErrorOpen(true);
    }
}

export const TimeReportPage: React.FC = () => {
    const [ confirmOpen, setConfirmOpen ] = useState(false);
    const [ selectedRow, setSelectedRow ] = useState<TimeReportRow | null>(null);
    const [ timeReportRows, setTimeReportRows ] = useState([] as TimeReportRow[]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ status, setStatus ] = useState<string>("All");
    const [ sortAscending, setSortAscending ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>("");
    const [ errorOpen, setErrorOpen ] = useState<boolean>(false);
    const [ page, setPage ] = useState<number>(0);
    const [ companyIdentifier, setCompanyIdentifier ] = useState<string | number | null>(null);
    const amount = 50;

    const fetchTimeReports = async (
        page: number,
        amount: number,
        status: string,
        sortAscending: boolean,
        companyIdentifier: string | number | null
    ) => {
        setIsLoading(true);
        try {
            const result = await Api().admin.getTimeReports(page, amount, status, sortAscending, companyIdentifier);
            setTimeReportRows(result);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTimeReports(page, amount, status, sortAscending, companyIdentifier).then(() => setIsLoading(false));
    }, [ page, amount, status, sortAscending, companyIdentifier ]);

    const handleOpenConfirmDialog = (row: any) => {
        if (row.status === 'New') {
            return;
        }
        setSelectedRow(row);
        setConfirmOpen(true);
    };

    const handleConfirmAction = async () => {
        if (selectedRow) {
            try {
                await handleCancelTimeReport(selectedRow, setError, setErrorOpen);
                const updatedData = timeReportRows.map(row =>
                    row.timeReportId === selectedRow.timeReportId ? { ...row, status: 'Rejected' } : row
                );
                setTimeReportRows(updatedData);
            } catch (error) {
                console.error(error);
            }
        }
        setConfirmOpen(false);
        fetchTimeReports(page, amount, status, sortAscending, companyIdentifier);
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(0, prevPage - 1));
    };

    const handleSetCompanyIdentifier = (companyIdentifier: string | number | null) => {
        setCompanyIdentifier(companyIdentifier);
    };

    return (
        <LoggedInPageContainer>
            <div>
                <H3>Time Reports</H3>
                {isLoading ? (
                    <div>
                        <CircularProgress size={18} color="inherit"/>
                        <br/>
                        <h4>Fetching time report, please wait...</h4>
                    </div>
                ) : (
                    <>
                        {RenderTimeReportTable(
                            timeReportRows,
                            handleOpenConfirmDialog,
                            isLoading,
                            status,
                            setStatus,
                            setPage,
                            sortAscending,
                            setSortAscending,
                            handlePreviousPage,
                            handleNextPage,
                            page,
                            companyIdentifier,
                            handleSetCompanyIdentifier
                        )}
                    </>
                )}
            </div>
            <ConfirmDialog
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
                selectedRow={selectedRow}
                handleConfirmAction={handleConfirmAction}
            />
            <ErrorDialog
                errorOpen={errorOpen}
                setErrorOpen={setErrorOpen}
                error={error}
            />
        </LoggedInPageContainer>
    );
};
