import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3, Text } from "components/Typography/text.styled";
import { FontSize } from "config/font";
import React, { useEffect, useState } from "react";
import { useAuthStore } from "stores/authStore/authStore";
import { CircularProgress, Input, Modal } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Api } from "services/api/api.service";
import { FontFamily } from "config/font";
import { useTheme } from "@mui/material/styles";
import { Color } from "config/colors";
import { ButtonStyled } from "components/buttons/buttons.styled";
import styled from "styled-components";
import { CustomIcon } from "components/icon/customIcon.component";

type PoolWorker = {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  staffingPoolId: number;
  workerId: number;
};
type PoolCompany = {
  id: number;
  name: string;
  staffingPoolId: number;
  companyId: number;
};
export const StaffingPage: React.FC = () => {
  const theme = useTheme();
  const [authState] = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);

  const [staffingPools, setStaffingPools] = useState<
    { id: number; name: string }[]
  >([]);
  const [staffingModal, setStaffingModal] = useState(false);
  const [newPoolName, setNewPoolName] = useState("");
  const [workerIds, setWorkerIds] = useState("");
  const [companyIds, setCompanyIds] = useState("");
  const [showPoolInfo, setShowPoolInfo] = useState(false);
  const [currentPool, setCurrentPool] = useState<{
    id: number;
    name: string;
  }>();
  const [poolCompanies, setPoolCompanies] = useState<PoolCompany[]>([]);
  const [poolWorkers, setPoolWorkers] = useState<PoolWorker[]>([]);

  useEffect(() => {
    if (authState.signedIn) {
      getInitialData();
    }
  }, [authState]);

  const getInitialData = () => {
    if (authState.signedIn) {
      setIsLoading(true);
      Api()
        .admin.getStaffingPools()
        .then((res) => {
          setStaffingPools(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showPoolInfo) {
      getCompaniesInPool();
      getWorkersInPool();
    }
  }, [showPoolInfo, currentPool]);

  const handleSubmit = () => {
    setIsLoading(true);
    if (companyIds.trim() !== "" && currentPool) {
      const Ids = companyIds.split(",");
      let newCompanies: PoolCompany[] = [];

      Promise.all(
        Ids.map((id) => {
          Api()
            .admin.addCompanyToStaffingPool(parseInt(id.trim()), currentPool.id)
            .then((res) => {
              newCompanies.push(res);
              setPoolCompanies([...newCompanies, ...poolCompanies]);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("ERROR ADDING COMPANIES TO STAFFING POOL", err);
              setIsLoading(false);
            });
        })
      );
    }
    if (workerIds.trim() !== "" && currentPool) {
      const Ids = workerIds.split(",");
      let newWorkers: PoolWorker[] = [];
      Promise.all(
        Ids.map((id) => {
          Api()
            .admin.addWorkerToStaffingPool(parseInt(id.trim()), currentPool.id)
            .then((res) => {
              newWorkers.push(res);
              setPoolWorkers([...newWorkers, ...poolWorkers]);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("ERROR ADDING WORKER TO STAFFING POOL", err);
              setIsLoading(false);
            });
        })
      );
    }
  };

  const getCompaniesInPool = () => {
    if (currentPool) {
      setIsLoading(true);
      Api()
        .admin.getCompaniesInStaffingPool(currentPool.id)
        .then((res) => {
          setPoolCompanies(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const getWorkersInPool = () => {
    if (currentPool) {
      setIsLoading(true);
      Api()
        .admin.getWorkersInStaffingPool(currentPool.id)
        .then((res) => {
          setPoolWorkers(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const renderTable = (
    rows: { id: number; name: string }[],
    editRow: boolean = false
  ) => {
    return (
      <>
        {isLoading && <CircularProgress size={18} color="inherit" />}
        {!isLoading && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Pool name</TableCell>

                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: { id: number; name: string }) => (
                    <TableRow
                      onClick={() => {
                        setShowPoolInfo(true);
                      }}
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell align="right">
                        <span
                          onClick={() => {
                            setCurrentPool(row);
                          }}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Edit
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  return (
    <LoggedInPageContainer>
      <H3>Staffing</H3>
      <ButtonStyled
        onClick={() => {
          setStaffingModal(true);
        }}
      >
        Create staffing pool
      </ButtonStyled>
      {staffingPools.length > 0 && renderTable(staffingPools)}
      <Modal
        open={staffingModal}
        onClose={() => {
          setStaffingModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody style={{ maxWidth: "400px" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Text fontFamily={FontFamily.MontserratSemiBold}>
                Enter name for staffing pool
              </Text>
              <Input
                style={{ marginBottom: "20px", width: "90%" }}
                onChange={(e) => setNewPoolName(e.target.value)}
                placeholder="Ex. Simflexity Restaurant"
              />
              <ButtonStyled
                disabled={!newPoolName.trim()}
                onClick={() => {
                  setIsLoading(true);
                  Api()
                    .admin.createStaffingPool(newPoolName)
                    .then((res) => {
                      setStaffingPools([...staffingPools, res]);
                      setStaffingModal(false);
                      setIsLoading(false);
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsLoading(false);
                    });
                }}
                backgroundColor={Color.BurntSienna}
                color={Color.White}
              >
                Save
              </ButtonStyled>
            </>
          )}
        </ModalBody>
      </Modal>
      <Modal
        open={showPoolInfo}
        onClose={() => {
          setShowPoolInfo(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
              >
                {currentPool?.name}
              </Text>
              <Row>
                <Column>
                  <Text
                    fontSize={FontSize.Standard}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    Companies with access to this pool
                  </Text>
                  <ScrollList>
                    {poolCompanies.map((company) => (
                      <CompanyRow>
                        <Text
                          fontSize={FontSize.Large}
                          style={{ margin: "0px" }}
                        >
                          {company.companyId}. {company.name}
                        </Text>
                        <CustomIcon
                          onClick={() => {
                            setIsLoading(true);
                            Api()
                              .admin.deleteCompanyFromStaffingPool(
                                company.staffingPoolId,
                                company.companyId
                              )
                              .then(() => {
                                setPoolCompanies(
                                  poolCompanies.filter(
                                    (pool) =>
                                      pool.companyId !== company.companyId
                                  )
                                );
                                setIsLoading(false);
                              })
                              .catch((err) => {
                                console.log(
                                  "ERROR REMOVING COMPANY FROM STAFFING POOL",
                                  err
                                );
                                setIsLoading(false);
                              });
                          }}
                          name="trashcan"
                          size={"20px"}
                          color={Color.Destructive}
                        />
                      </CompanyRow>
                    ))}
                    {poolCompanies.length === 0 && (
                      <Text>No companies in this pool</Text>
                    )}
                  </ScrollList>
                </Column>
                <Column>
                  <Text
                    fontSize={FontSize.Standard}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    Workers in this pool
                  </Text>
                  <ScrollList>
                    {poolWorkers.map((worker) => (
                      <CompanyRow>
                        <Text
                          fontSize={FontSize.Large}
                          style={{ margin: "0px" }}
                        >
                          {worker.workerId}. {worker.firstName}{" "}
                          {worker.lastName}
                        </Text>
                        <CustomIcon
                          name="trashcan"
                          size={"20px"}
                          color={Color.Destructive}
                          onClick={() => {
                            setIsLoading(true);
                            Api()
                              .admin.deleteWorkerFromStaffingPool(
                                worker.staffingPoolId,
                                worker.workerId
                              )
                              .then(() => {
                                setPoolWorkers(
                                  poolWorkers.filter(
                                    (pool) => pool.workerId !== worker.workerId
                                  )
                                );
                                setIsLoading(false);
                              })
                              .catch((err) => {
                                console.log(
                                  "ERROR REMOVING WORKER FROM STAFFING POOL",
                                  err
                                );
                                setIsLoading(false);
                              });
                          }}
                        />
                      </CompanyRow>
                    ))}
                    {poolWorkers.length === 0 && (
                      <Text>No workers in this pool</Text>
                    )}
                  </ScrollList>
                </Column>
              </Row>
              <Divider />
              <Row>
                <Column>
                  <Text
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    Add Companies by ids
                  </Text>
                  <Input
                    onChange={(e) => setCompanyIds(e.target.value)}
                    placeholder="Ex. 33,42,56"
                    value={companyIds}
                  />
                </Column>
                <Column>
                  <Text
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    Add workers by ids
                  </Text>

                  <Input
                    onChange={(e) => setWorkerIds(e.target.value)}
                    placeholder="Ex. 33,42,56"
                    value={workerIds}
                  />
                </Column>
              </Row>
              <ButtonStyled
                disabled={workerIds.trim() === "" && companyIds.trim() === ""}
                onClick={() => {
                  handleSubmit();
                }}
                backgroundColor={Color.BurntSienna}
                color={Color.White}
              >
                Save
              </ButtonStyled>
            </>
          )}
        </ModalBody>
      </Modal>
    </LoggedInPageContainer>
  );
};

const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  width: 800px;
  padding: 20px 20px 30px;
  border-radius: 8px;
  background-color: ${Color.White};

  p {
    margin-bottom: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
  padding: 16px;
  width: 300px;
`;
const Divider = styled.div`
  width: 80%;
  background-color: ${Color.SeaBlue200};
  height: 3px;
  margin-bottom: 18px;
`;

const CompanyRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid ${Color.SeaBlue400};
`;
