import { FeatureConfiguration } from "model/FeatureConfiguration";

export type GlobalActions =
   | {
        type: "SET_IS_LOADING";
        payload: boolean;
     }
   | {
        type: "SET_FEATURE_CONFIG";
        payload: FeatureConfiguration[]
     };

export type GlobalStoreState = {
  isLoading: boolean;
  featureConfiguration: FeatureConfiguration[];
};

export const initGlobalStoreState: GlobalStoreState = {
  isLoading: false,
  featureConfiguration: []
};

export const globalStoreReducer = (
  state: GlobalStoreState,
  action: GlobalActions
) => {
  switch (action.type) {
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_FEATURE_CONFIG": {
      return { ...state, featureConfiguration: action.payload};
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
