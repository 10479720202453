import { useEffect, useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { NavBar } from "components/nav/navBar/navBar.component";
import {
  PrivateRoute,
  PublicRoute,
} from "./components/customRoute/customRoute.component";
import { LoginPage } from "./pages/login/login.page";
import { CompaniesPage } from "./pages/companies/companies.page";
import { CompanyGroupPage } from "./pages/company-groups/companyGroup.page";
import { TimeReportPage } from './pages/time-reports/timeReports.page';
import { WorkersPage } from "./pages/workers/workers.page";
import { StartPage } from "./pages/start/start.page";
import { Api } from "services/api/api.service";
import { useGlobalStore } from "stores/globalStore/globalStore";
import { FeatureConfiguration } from "model/FeatureConfiguration";
import { Drawer } from "components/nav/drawer/drawer.component";
import { GigsPage } from "./pages/gigs/gigs.page";
import { StaffingPage } from "./pages/staffing/staffing.page";
import { WithdrawalsPage } from "./pages/withdrawals/withDrawals.page";

export const AdminApp = () => {
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(true);
  const [globalState, globalDispatch] = useGlobalStore();

  useEffect(() => {
    Api()
      .getFeatureConfiguration()
      .then((res) => {
        globalDispatch({
          type: "SET_FEATURE_CONFIG",
          payload: res as FeatureConfiguration[],
        });
      });
  }, []);

  return (
    <>
      <NavBar open={open} setOpen={setOpen} />
      <Drawer open={open} setOpen={setOpen} />
      <Switch>
        <PublicRoute path={`${path}/login`}>
          <LoginPage />
        </PublicRoute>
        <PrivateRoute path={`${path}/start`}>
          <StartPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/companies`}>
          <CompaniesPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/gigs`}>
          <GigsPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/company-groups`}>
          <CompanyGroupPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/time-reports`}>
          <TimeReportPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/withdrawals`}>
          <WithdrawalsPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/workers`}>
          <WorkersPage />
        </PrivateRoute>
        <PrivateRoute path={`${path}/staffing`}>
          <StaffingPage />
        </PrivateRoute>
      </Switch>
    </>
  );
};
